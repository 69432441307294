<template>
  <footer id="footer" v-if="this.$store.state.isLanguage === 'FR'">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4 footer-info">
            <div class="logo me-auto">
              <router-link to="/"
                ><img
                  style="width: 50px"
                  src="https://go2babi.lce-ci.com/public/LOGO%20ELECTION.PNG"
                  alt="image_election"
              /></router-link>
            </div>

            <p>
              20 BP 1081, Abidjan 20
              <a
                href="https://maps.app.goo.gl/5KWoJua19tMYR5qi7"
                title="Afficher sur Google Map"
              >
                <i
                  class="bx bx-map"
                  style="font-size: 24px; color: #128743"
                ></i>
              </a>
              <br />
              <strong>Contact :</strong> +225 27 22 48 05 14 / +225 27 22 48 05
              15<br />
              <strong>Email:</strong> contact@pdcirda.ci<br />
            </p>

            <div class="social-links mt-3">
              <a
                href="https://www.facebook.com/pdcirdaofficiel1"
                class="facebook"
                ><i class="bx bxl-facebook"></i
              ></a>
              <a href="https://www.youtube.com/@PDCITV" class="youtube"
                ><i class="bx bxl-youtube"></i
              ></a>
              <a href="https://twitter.com/pdcirdaofficiel" class="twitter"
                ><i class="bx bxl-twitter"></i
              ></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-4">
            <div class="btn-don" style="text-align: center">
              <!-- <button class="btn">
                <router-link id="don" to="/don">Faire un don</router-link>
              </button> -->
            </div>
          </div>
          <div class="col-lg-4 col-md-3 footer-newsletter">
            <p>NEWSLETTER</p>
            <form @submit.prevent="sendEmail" v-if="visibleInput">
              <input type="email" name="email" v-model="email" required />
              <input
                type="submit"
                :value="!isLoading ? 'S\'enregistrer' : 'Chargement'"
              />
            </form>
            <div
              v-if="!visibleInput"
              class="text-center fw-bold"
              style="color: white"
            >
              Your subscription to the newsletter has been successfully
              registered. An automatic email has just been sent to the email
              address.
            </div>
            <div v-if="msgEmail" class="text-center">
              <span v-for="(item, index) in msgEmail" :key="index">
                <span class="fw-bold text-danger">{{ item }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row footer-conteneur">
          <div class="col-lg-3 col-md-3 footer-links">
            <h4>LE PARTI</h4>
            <ul>
              <li>
                <router-link
                  to="/mot-president"
                  @click="goToAdresse('/mot-president')"
                  >Mot du Président</router-link
                >
              </li>
              <li>
                <router-link to="/histoire" @click="goToAdresse('/histoire')"
                  >Histoire du PDCI</router-link
                >
              </li>
              <li>
                <router-link
                  to="/presidents"
                  @click="goToAdresse('/presidents')"
                  >Les Présidents élus</router-link
                >
              </li>
              <!-- <li>
                <router-link to="/statuts-reglements"
                  >Statuts et Règlements</router-link
                >
              </li> -->
            </ul>
          </div>

          <div class="col-lg-3 col-md-3 footer-links">
            <h4>LA GOUVERNANCE</h4>
            <ul>
              <li>
                <router-link
                  to="/cabinet-president"
                  @click="goToAdresse('/cabinet-president')"
                >
                  Le Cabinet du Président</router-link
                >
              </li>
              <li>
                <router-link
                  to="/vice-presidents"
                  @click="goToAdresse('/vice-presidents')"
                  >Le Collège des Vice-Présidents</router-link
                >
              </li>
              <li>
                <router-link
                  to="/secretariat-executif"
                  @click="goToAdresse('/secretariat-executif')"
                  >Le Secrétariat Exécutif</router-link
                >
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-3 footer-links">
            <h4 @click.prevent="this.$router.push({ path: '/chantier' })">
              LES CHANTIERS
            </h4>
            <ul>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-1')"
                  >Restructuration et modernisation</router-link
                >
              </li>

              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-2')"
                  >Redynamisation du parti</router-link
                >
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-3')"
                  >Renforcement de l'ancrage</router-link
                >
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-4')"
                  >Préparation des élections</router-link
                >
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-5')"
                  >Sytème de communication</router-link
                >
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-6')"
                  >Partenariats politiques</router-link
                >
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-7')"
                  >PDCI-RDA à l'international</router-link
                >
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-3 footer-links">
            <h4>L'ACTUALITE</h4>
            <ul>
              <li><router-link to="/actualite">Actualités</router-link></li>
              <li><a href="https://www.youtube.com/@PDCITV">PDCI TV</a></li>
              <li>
                <router-link class="lien-add-inscription" to="/login"
                  >Se connecter</router-link
                >
              </li>
              <li>
                <router-link class="lien-add-inscription" to="/inscription"
                  >S'inscrire</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        2024 &copy; Copyright <strong><span>PDCI - RDA</span></strong
        >. Tous droits réservés
      </div>
    </div>
  </footer>
  <footer id="footer" v-else>
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-3 footer-info">
            <div class="logo me-auto">
              <router-link to="/"
                ><img
                  style="width: 50px"
                  src="https://go2babi.lce-ci.com/public/LOGO%20ELECTION.PNG"
                  alt="image_election"
              /></router-link>
            </div>

            <p>
              20 BP 1081, Abidjan 20
              <a
                href="https://maps.app.goo.gl/5KWoJua19tMYR5qi7"
                title="Afficher sur Google Map"
              >
                <i
                  class="bx bx-map"
                  style="font-size: 24px; color: #128743"
                ></i>
              </a>
              <br />
              <strong>Phone :</strong> +225 27 22 48 05 14/ +225 27 22 48 05
              15<br />
              <strong>Email:</strong> contact@pdcirda.ci<br />
            </p>

            <div class="social-links mt-3">
              <a
                href="https://www.facebook.com/pdcirdaofficiel1"
                class="facebook"
                ><i class="bx bxl-facebook"></i
              ></a>
              <a href="https://www.youtube.com/@PDCITV" class="youtube"
                ><i class="bx bxl-youtube"></i
              ></a>
              <a href="https://twitter.com/pdcirdaofficiel" class="twitter"
                ><i class="bx bxl-twitter"></i
              ></a>
            </div>
          </div>
          <div class="col-lg-4 col-md-3">
            <div class="btn-don" style="text-align: center">
              <!-- <button class="btn">
                <router-link id="don" to="/don">Make a donation</router-link>
              </button> -->
            </div>
          </div>

          <div class="col-lg-4 col-md-3 footer-newsletter">
            <p>NEWSLETTER</p>
            <form @submit.prevent="sendEmail" v-if="visibleInput">
              <input type="email" name="email" v-model="email" required />
              <input
                type="submit"
                :value="!isLoading ? 'Register' : 'Chargement'"
              />
            </form>
            <div
              v-if="!visibleInput"
              class="text-center fw-bold"
              style="color: white"
            >
              Your subscription to the newsletter has been successfully
              registered. An automatic email has just been sent to the email
              address.
            </div>
            <div v-if="msgEmail" class="text-center">
              <span v-for="(item, index) in msgEmail" :key="index">
                <span class="fw-bold text-danger">{{ item }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row footer-conteneur">
          <div class="col-lg-3 col-md-3 footer-links">
            <h4>Our Party</h4>
            <ul>
              <li>
                <router-link
                  to="/mot-president"
                  @click="goToAdresse('/mot-president')"
                  >The President's message</router-link
                >
              </li>
              <li>
                <router-link to="/histoire" @click="goToAdresse('/histoire')"
                  >Our History</router-link
                >
              </li>
              <li>
                <router-link
                  to="/presidents"
                  @click="goToAdresse('/presidents')"
                  >The Elected Presidents</router-link
                >
              </li>
              <li>
                <router-link
                  to="/statuts-reglements"
                  @click="goToAdresse('/statuts-reglements')"
                  >Statutes and Regulations</router-link
                >
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-3 footer-links">
            <h4>Our Leadership</h4>
            <ul>
              <li>
                <router-link
                  to="/cabinet-president"
                  @click="goToAdresse('/cabinet-president')"
                >
                  The Office of the president</router-link
                >
              </li>
              <li>
                <router-link
                  to="/vice-presidents"
                  @click="goToAdresse('/vice-presidents')"
                  >The college of Vice-Presidents</router-link
                >
              </li>
              <li>
                <router-link
                  to="/secretariat-executif"
                  @click="goToAdresse('/secretariat-executif')"
                  >The Executive Secretariate</router-link
                >
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-3 footer-links">
            <h4>Our Priorities</h4>
            <ul>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-1')"
                  >Restructure and modernize the Party</router-link
                >
              </li>

              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-2')"
                  >Ensure internal dynamism and vitality of the
                  Party</router-link
                >
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-3')"
                  >Strengthen the party's territorial and social
                  anchorage</router-link
                >
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-4')"
                  >An efficient system for elections and elected officials
                </router-link>
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-5')"
                  >An effective communication system</router-link
                >
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-6')"
                  >Relevant political partnerships</router-link
                >
              </li>
              <li>
                <router-link
                  to="/chantier"
                  @click="goToChantierParam('pilier-7')"
                  >Reposition PDCI-RDA in the international arena.</router-link
                >
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-3 footer-links">
            <h4>Updates and News</h4>
            <ul>
              <li><router-link to="/actualite">News</router-link></li>
              <li><a href="https://www.youtube.com/@PDCITV">PDCI TV</a></li>
              <li>
                <router-link class="lien-add-inscription" to="/login"
                  >Login</router-link
                >
              </li>
              <li>
                <router-link class="lien-add-inscription" to="/inscription"
                  >Register</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        2024 &copy; Copyright <strong><span>PDCI - RDA</span></strong
        >. All rights reserved
      </div>
    </div>
  </footer>
</template>
<script>
import instance from "../api";
export default {
  name: "FooterView",
  data() {
    return {
      email: "",
      isLoading: "",
      msgEmail: "",
      visibleInput: true,
    };
  },
  methods: {
    goToChantierParam(texte) {
      this.$store.commit("TOOGLE_PALLIER", texte);
    },
    async sendEmail() {
      this.isLoading = true;
      try {
        const response = await instance.post("newsletters", {
          email: this.email,
        });
        if (response.data.status === true) {
          this.email = "";
          this.isLoading = false;
          this.msgEmail = "";
          this.visibleInput = false;
        }
        //console.log("/newsletters", response.data);
      } catch (error) {
        //console.log("ERROR", error.response.data.message.email);
        this.msgEmail = error.response.data.message.email;

        this.isLoading = false;
        this.visibleInput = true;
        setTimeout(() => {
          this.msgEmail = "";
        }, 3200);
      }
    },
    goToAdresse(chemin) {
      this.$router.push({ path: chemin });
      this.$store.commit("TOOGLE_PALLIER", "pilier-1");
    },
  },
};
</script>
