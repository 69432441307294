import axios from "axios";
export const lienImage = "https://apiweb.pdcirda.ci/public/image/";
export const lienMedia = "https://apiweb.pdcirda.ci/public/fichier/";
const instance = axios.create({
  baseURL: "https://apiweb.pdcirda.ci/api",
});

instance.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem("token"));
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default instance;
