<template>
  <div>
    <Header />
    <div style="padding: 3em 0 0 0">
      <router-view />
    </div>
  </div>
  <FooterView />
  <div
    v-if="!isOpenAtChatBot"
    @click="isOpenAtChatBot = !isOpenAtChatBot"
    class="conteneur-btn-chat-bot"
  >
    <i class="bi bi-robot"></i>
  </div>
  <!-- <ConversationsView /> -->
  <ChatBot
    :isOpen="isOpenAtChatBot"
    @update="(value) => (isOpenAtChatBot = value)"
  />
</template>
<script>
// import ConversationsView from "@/components/conversations/ConversationsView";
import ChatBot from "@/components/ChatBot.vue";
import Header from "@/components/Header";
import FooterView from "@/components/Footer";
export default {
  name: "App",
  components: {
    Header,
    FooterView,
    ChatBot,
    // ConversationsView,
  },
  data() {
    return {
      isOpenAtChatBot: false,
    };
  },
};
</script>

<style lang="scss">
.ProseMirror {
  border: 1px solid black;
  border-radius: 5px;
  margin-left: 0.5em;
  text-align: left;
  padding: 0 1em 0 0.5em;
  position: absolute;
  width: 90%;
  height: 95%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.ProseMirror p {
  padding: 0;
  margin: 0;
}
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #1d1d1d;
  }
}

.p-paginator {
  width: clamp(80%, 50vw, 100%) !important;
  margin: 0 auto;
  background: none !important;
}
.conteneur-btn-chat-bot {
  width: 50px;
  height: 50px;
  background: #128743;
  border-radius: 100%;
  color: white;
  position: fixed;
  bottom: 1em;
  right: 0.6em;
  line-height: 50px;
  z-index: 98;
  cursor: pointer;
}
.vueperslide {
  background-position-x: -95%;
}
.vueperslides__arrow svg {
  background: rgba(0, 0, 0, 0.78) !important;
  border-radius: 35% !important;
  color: white !important;
  width: 2.5em !important;
  padding: 0.8em !important;
}
.texte-coupe {
  width: 100%; /* Définissez la largeur maximale du conteneur */
  height: 100px;
  padding: 0.5em;
  overflow: hidden; /* Masque le texte dépassant du conteneur */
  text-overflow: ellipsis; /* Affiche des points de suspension (...) si le texte est coupé */
}
.p-image-preview-indicator {
  color: white !important;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s;
  border: none;
  padding: 0;
  z-index: 990;
}
.other_texte-coupe_conversation {
  width: 65%; /* Définissez la largeur maximale du conteneur */
  height: auto; /* Définissez la hauteur maximale du conteneur */
  padding: 0.5em;
  overflow: hidden; /* Masque le texte dépassant du conteneur */
  white-space: nowrap; /* Empêche le texte de passer à la ligne suivante */
  text-overflow: ellipsis; /* Affiche des points de suspension (...) si le texte est coupé */
}
.n-carousel.n-carousel--right .n-carousel__dots {
  right: 12px !important;
}
.n-collapse-item__header--active {
  background-color: #128743 !important;
}
.n-collapse-item__header--active .n-collapse-item__header-main {
  color: white !important;
}
.p-tabview-nav {
  margin-bottom: 10px;
  justify-content: center;
}
.other_texte-coupe {
  width: 100%; /* Définissez la largeur maximale du conteneur */
  height: auto; /* Définissez la hauteur maximale du conteneur */
  padding: 0.5em;
  overflow: hidden; /* Masque le texte dépassant du conteneur */
  white-space: nowrap; /* Empêche le texte de passer à la ligne suivante */
  text-overflow: ellipsis; /* Affiche des points de suspension (...) si le texte est coupé */
}
#shadow-host-companion {
  padding: 0;
}
.n-collapse {
  margin: 1.5em 0 !important;
}
.n-collapse-item__header-main {
  font-size: 1em !important;
  padding: 5px 20px !important;
  font-family: Montserrat, sans-serif;
}
.n-collapse-item-arrow {
  position: absolute !important;
  right: 0 !important;
}
.color1 {
  background: #ffe7e6;
  padding: 0.3em;
  height: auto;
  border-radius: 5px;
}
.trend-top-cap span,
.color2,
.color4 {
  padding: 0.3em 0.5em;
  border-radius: 5px;
  background: #fff9c6;
}

.trand-right-single {
  padding: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.236);
}
.p-paginator-first,
.p-paginator-prev,
.p-paginator-next,
.p-paginator-last {
  display: none;
}
.trend-top-cap {
  position: absolute;
  bottom: 0;
  text-align: left;
  padding: 1em;
}
.trend-top-img {
  border-radius: 10px;
  position: relative;
  margin: 0 0 5em 0;
  height: 400px;
}
.trand-right-cap {
  text-align: left;
  padding: 0 1em;
}
h4 {
  padding: 1em;
  font-size: 1em;
}
h4 a {
  color: black !important;
}
.trend-bottom-cap {
  text-align: left;
  padding: 1em 0.5em;
}
.trend-bottom-cap h4 {
  padding: 0.5em;
}
.trend-top-img img {
  border-radius: 10px;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.accordion-app p strong {
  font-size: 2rem;
  color: #eba334;
}
.new-style_accordion {
  color: #eba334 !important;
  background-color: transparent;
  border-radius: 0;
  border-left: 5px solid #eba334;
  font-weight: bold;
}
.new-style_accordion:hover {
  color: #eba334 !important;
  background-color: transparent;
  border-radius: 0;
  border-left: 5px solid #eba334;
  font-weight: bold;
}
.accordion-app h2 {
  font-weight: bold;
  color: #128743;
}
.accordion-app h2 span {
  font-size: 0.5em;
  display: block;
  color: orange;
}
.accordion-app h2::after {
  content: "";
  display: flex;
  width: 20%;
  margin-top: 10px;
  border-bottom: 2px solid #128743;
}
.modal-content {
  position: relative;
  z-index: 21;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
}
.nav-pills .nav-link {
  border-radius: 0;
}
.modal-content_chat_bot {
  position: relative;
  z-index: 21;
}
.news-item {
  list-style-type: none;
}
.close-modal {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(6, 6, 6, 0.264);
  z-index: 20;
  height: 100%;
}
.footer-conteneur {
  text-align: left;
}
.vueperslide__title {
  text-align: left !important;
  color: white;
  font-size: 1.1em;
  padding: 0 4em;
  font-weight: 500 !important;
  text-transform: uppercase;
}
.mot-du-president,
.secretariat-exec {
  text-transform: uppercase;
}
.vueperslide__content {
  text-align: left !important;
  padding: 0 1.7em;
  color: white;
  font-size: 2.5em;
  font-weight: bold;
}
.n-collapse-item__header-main {
  font-size: 1.3em !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #00b761;
  border-color: #ecfdf5;
  color: #ffefeffc;
}
.conteneur-image-president {
  margin: 3em 0;
}
.vueperslide__content .ecriteau {
  font-size: 1em !important;
}
.vueperslide__content-wrapper {
  align-items: flex-start !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#vidFrame {
  display: none;
}
#vidFrame1 {
  display: none;
}
.news-item {
  margin: 0 5em;
}
.mymodal {
  background: rgba(6, 6, 6, 0.264);
  position: fixed;
  top: 0;
  padding: 3em 0 0 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  place-items: center;
  justify-content: "center";
}
.close {
  text-align: left;
}
.js-hidden {
  margin: 0 !important;
}
.trending-tittle {
  display: flex;
  gap: 0.1em;
  height: 45px;
}
.trending-tittle strong {
  height: 44px !important;
}
@media (max-width: 768px) {
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 70%;
  }
}
@media screen and (max-width: 500px) {
  .p-paginator {
    width: 100% !important;
    padding: 0.5em;
  }
  .trending-animated {
    width: 100% !important;
  }
}
@media screen and (max-width: 1234px) {
  .vueperslides__bullets {
    margin-top: -4em;
  }
  #about {
    margin-top: 0;
  }
}
.trending-animated {
  width: 100% !important;
  border: 1px solid red;
  height: auto;
}
.p-dialog-mask {
  z-index: 80 !important;
}
.marquee {
  padding: 9px;
}
.p-dialog {
  width: auto !important;
}
.navbar {
  flex: 1;
  justify-content: center;
}
#hero {
  height: 800px;
}
.js-hidden {
  padding: 0 !important;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.box-form {
  width: 60%;
  padding: 1em;
  margin: 1em auto;
}
@media screen and (max-width: 887px) {
  .broken-parti {
    display: block;
  }
  .vueperslide__content-wrapper:not(
      .vueperslide__content-wrapper--outside-top
    ):not(.vueperslide__content-wrapper--outside-bottom) {
    align-content: center !important;
  }
  .vueperslide__content-wrapper {
    padding: 0 3em !important;
    align-items: center !important;
  }
  #hero h2.text-gros,
  #hero h1.text-gros .paragraphe {
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
}
@media screen and (max-width: 576px) {
  .vueperslide__content {
    font-size: 1.8em;
    margin-bottom: -10em;
    padding: 0;
    width: 100%;
  }
  .vueperslide__content h1 {
    text-align: center;
  }
  .box-form {
    width: 100%;
    padding: 1em;
    margin: 1em auto;
  }
  .vueperslide__content-wrapper {
    padding: 0 !important;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: none !important;
  }
  #about {
    margin-top: -3em;
  }
  .paragraphe {
    font-size: 0.9em !important;
    text-align: center;
    font-weight: bold;
  }
  .vueperslide__title {
    font-size: 13px;
    padding: 0 5em;
    padding: 0;
  }
}
</style>
