<template>
  <div v-if="this.$store.state.isLanguage === 'EN'">
    <div class="list-menu" v-show="toggleMenu">
      <button class="btn-close">
        <i class="bi bi-x-lg" @click.prevent="toggleMenu = !toggleMenu"></i>
      </button>

      <div class="menu-list-mobile">
        <div
          class="dropdown"
          style="padding: 1em; text-transform: uppercase"
          v-if="this.$store.state.user"
        >
          <div @click="mobileMenu = !mobileMenu" class="list-menu-nav">
            <a href="#"
              ><span>{{ this.$store.state.user.nom }} </span></a
            >
            <i class="bi bi-person-circle mx-2"></i>
            <i class="bi bi-chevron-down mx-1" v-if="!mobileMenu"></i>
            <i class="bi bi-chevron-up mx-1" v-if="mobileMenu"></i>
          </div>
          <div
            v-show="mobileMenu"
            style="
              padding: 1em;
              box-shadow: 1px 1px 1px 1px black;
              margin: 1em 0;
              background: white;
            "
            class="style_menu_lien"
          >
            <a
              href="#"
              class="d-block"
              @click.prevent="goToSpace('mobile', '/Mon_espace')"
            >
              <i class="bi bi-person-square mx-2"></i> My profil</a
            >
            <!-- <a
              href="#"
              class="d-block"
              @click.prevent="goToSpace('mobile', '/evenement')"
            >
              <i class="bi bi-calendar-event mx-2"></i>Event</a
            > -->
            <a
              href="#"
              class="d-block"
              @click.prevent="goToSpace('mobile', '/forum')"
            >
              <i class="bi bi-person-video2 mx-2"></i>Forum</a
            >
            <a href="#" class="d-block" @click.prevent="DeconnecterMobile"
              ><i class="bi bi-box-arrow-right mx-2"></i>Logout</a
            >
          </div>
        </div>
        <router-link @click.prevent="toogleMyMenu" class="menu-acueil" to="/"
          >Home
        </router-link>
        <n-collapse arrow-placement="right">
          <n-collapse-item title="Our Party" name="1">
            <div class="sub-menu">
              <ul class="menu-mobile">
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/mot-president"
                    >The President's message
                  </router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/histoire"
                    >Our History
                  </router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/presidents"
                    >The Elected Presidents
                  </router-link>
                </li>
                <!-- <li>
                  <router-link
                    @click.prevent="toogleMyMenu"
                    to="/statuts-reglements"
                    >Statutes and regulations
                  </router-link>
                </li> -->
              </ul>
            </div>
          </n-collapse-item>
          <n-collapse-item title="Our Leadership" name="2">
            <div class="sub-menu">
              <ul class="menu-mobile">
                <li>
                  <router-link
                    @click.prevent="toogleMyMenu"
                    to="/cabinet-president"
                  >
                    The Office of the president
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.prevent="toogleMyMenu"
                    to="/vice-presidents"
                    >The college of Vice-Presidents
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.prevent="toogleMyMenu"
                    to="/secretariat-executif"
                    >The Executive Secretariate
                  </router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/districts"
                    >The High Representatives
                  </router-link>
                </li>
              </ul>
            </div>
          </n-collapse-item>
          <n-collapse-item title="Our Priorities" name="3">
            <div class="sub-menu">
              <ul class="menu-mobile">
                <li>
                  <router-link to="/chantier" @click="goToChantierParamMobile"
                    >Restructure and modernize the Party</router-link
                  >
                </li>

                <li>
                  <router-link to="/chantier" @click="goToChantierParamMobile"
                    >Ensure internal dynamism and vitality of the
                    Party</router-link
                  >
                </li>
                <li>
                  <router-link to="/chantier" @click="goToChantierParamMobile"
                    >Strengthen the party's territorial and social
                    anchorage</router-link
                  >
                </li>
                <li>
                  <router-link to="/chantier" @click="goToChantierParamMobile"
                    >An efficient system for elections and elected officials
                  </router-link>
                </li>
                <li>
                  <router-link to="/chantier" @click="goToChantierParamMobile"
                    >An effective communication system</router-link
                  >
                </li>
                <li>
                  <router-link to="/chantier" @click="goToChantierParamMobile"
                    >Relevant political partnerships</router-link
                  >
                </li>
                <li>
                  <router-link to="/chantier" @click="goToChantierParamMobile">
                    Reposition PDCI-RDA in the international arena.</router-link
                  >
                </li>
              </ul>
            </div>
          </n-collapse-item>
          <n-collapse-item title="Updates and News" name="4">
            <div class="sub-menu">
              <ul class="menu-mobile">
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/actualite"
                    >News
                  </router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/mediatheque"
                    >Media library
                  </router-link>
                </li>
                <li>
                  <router-link to="/evenement">Events</router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/decisions"
                    >Decisions
                  </router-link>
                </li>
              </ul>
            </div>
          </n-collapse-item>
        </n-collapse>
        <div>
          <button class="btn">
            <!-- <router-link
              @click.prevent="toogleMyMenu"
              class="lien-add-inscription-mobile"
              to="/inscription"
            >
              JOIN
            </router-link> -->
            <a
              target="_blank"
              href="https://adhesion.pdcirda.ci/"
              class="lien-add-inscription-mobile"
              >Join US</a
            >
          </button>
          <button class="btn d-block mt-5">
            <router-link
              @click.prevent="toogleMyMenu"
              style="background: #cc9b20"
              class="lien-add-inscription-mobile"
              to="/toussurlalisteelectorale"
            >
              All on the electoral list
            </router-link>
          </button>
          <!-- <button class="btn d-block mt-5">
            <router-link
              @click.prevent="toogleMyMenu"
              class="lien-add-inscription-mobile"
              to="/login"
            >
              SIGN IN
            </router-link>
          </button> -->
        </div>
      </div>
    </div>
    <header id="header" class="d-flex align-items-center">
      <div class="container-fluid d-flex align-items-center">
        <div class="logo me-auto">
          <router-link to="/"
            ><img
              src="https://go2babi.lce-ci.com/public/logo%20pdci%20(1).PNG"
              alt=""
              title="Accueil"
          /></router-link>
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <router-link to="/" @click="goToAdresse('/')">Home</router-link>
            </li>
            <li class="dropdown">
              <a href="#"
                ><span>Our Party</span> <i class="bi bi-chevron-down"></i
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/mot-president"
                    @click="goToAdresse('/mot-president')"
                    >The President's message</router-link
                  >
                </li>
                <li>
                  <router-link to="/histoire" @click="goToAdresse('/histoire')"
                    >Our History</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/presidents"
                    @click="goToAdresse('/presidents')"
                    >The Elected Presidents</router-link
                  >
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="#"
                ><span>Our Leadership</span> <i class="bi bi-chevron-down"></i
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/cabinet-president"
                    @click="goToAdresse('/cabinet-president')"
                  >
                    The Office of the president</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/vice-presidents"
                    @click="goToAdresse('/vice-presidents')"
                    >The college of Vice-Presidents</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/secretariat-executif"
                    @click="goToAdresse('/secretariat-executif')"
                  >
                    The Executive Secretariate</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/districts"
                    @click="goToAdresse('/districts')"
                  >
                    The High Representatives</router-link
                  >
                </li>
              </ul>
            </li>

            <!-- <li class="">
              <router-link to="/chantier" @click="goToAdresse('/chantier')"
                ><span>PILLARS</span>
                <i class="bi bi-chevron-down"></i>
              </router-link>
            </li> -->

            <li class="dropdown">
              <router-link to="/chantier" @click="goToAdresse('/chantier')"
                ><span>Our Priorities</span>
                <i class="bi bi-chevron-down"></i>
              </router-link>
              <ul>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-1')"
                    >Restructure and modernize the Party</router-link
                  >
                </li>

                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-2')"
                    >Ensure internal dynamism and vitality of the
                    Party</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-3')"
                    >Strengthen the party's territorial and social
                    anchorage</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-4')"
                    >An efficient system for elections and elected officials
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-5')"
                    >An effective communication system</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-6')"
                    >Relevant political partnerships</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-7')"
                    >Reposition PDCI-RDA in the international
                    arena.</router-link
                  >
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="#"
                ><span>Updates and News</span><i class="bi bi-chevron-down"></i
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/actualite"
                    @click="goToAdresse('/actualite')"
                    >News</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/mediatheque"
                    @click="goToAdresse('/mediatheque')"
                    >Media library
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/evenement"
                    @click="goToAdresse('/evenement')"
                    >Events
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/decisions"
                    @click="goToAdresse('/decisions')"
                    >Decisions</router-link
                  >
                </li>
              </ul>
            </li>

            <li class="btn-adherer">
              <button class="btn" style="margin-left: 15px">
                <!-- <router-link
                  class="lien-add-inscription"
                  to="/inscription"
                  @click="goToAdresse('/inscription')"
                  >JOIN</router-link
                > -->
                <a
                  target="_blank"
                  href="https://adhesion.pdcirda.ci/"
                  class="lien-add-inscription"
                  >Join US</a
                >
              </button>
            </li>

            <li>
              <button class="btn d-block">
                <router-link
                  style="background: #cc9b20"
                  class="lien-add-inscription-mobile"
                  to="/toussurlalisteelectorale"
                >
                  All on the electoral list
                </router-link>
              </button>
            </li>
            <!-- <li class="btn-adherer" v-if="!this.$store.state.user">
              <button class="btn" style="margin-left: 15px">
                <router-link
                  class="lien-add-inscription"
                  to="login"
                  @click="goToAdresse('/login')"
                  >Sign in</router-link
                >
              </button>
            </li> -->
            <li
              class="dropdown d-flex align-items-center"
              v-if="this.$store.state.user"
            >
              <i class="bi bi-person-circle icone-circle"></i>
              <a href="#"
                ><span>{{ this.$store.state.user.nom }}</span></a
              >
              <ul>
                <li class="text-start">
                  <a
                    href="#"
                    class="d-block"
                    @click.prevent="goToSpace('web', '/Mon_espace')"
                  >
                    <i class="bi bi-person-square mx-1"></i>My profil</a
                  >
                  <!-- <a
                    href="#"
                    class="d-block"
                    @click.prevent="goToSpace('web', '/evenement')"
                  >
                    <i class="bi bi-calendar-event mx-1"></i>Event</a
                  > -->
                  <a
                    href="#"
                    class="d-block"
                    @click.prevent="goToSpace('web', '/forum')"
                  >
                    <i class="bi bi-person-video2 mx-1"></i>Forum</a
                  >
                  <a href="#" class="d-block" @click.prevent="Deconnecter"
                    ><i class="bi bi-box-arrow-right mx-1"></i> Logout</a
                  >
                </li>
              </ul>
            </li>
          </ul>
          <i
            class="bi bi-list mobile-nav-toggle"
            @click.prevent="toggleMenu = !toggleMenu"
          ></i>
        </nav>
        <select v-model="chooseLanguage" @change="selectLanguage" class="p-1">
          <option class="FR">FR</option>
          <option class="EN">EN</option>
        </select>
        <!-- <div class="logo me-auto conteneur-image">
          <router-link to="/"
            ><img
              src="https://go2babi.lce-ci.com/public/LOGO%20ELECTION.PNG"
              alt=""
              title="Accueil"
          /></router-link>
        </div> -->
        <!-- .navbar -->
      </div>
    </header>
  </div>

  <div v-else>
    <div class="list-menu" v-show="toggleMenu">
      <button class="btn-close">
        <i class="bi bi-x-lg" @click.prevent="toggleMenu = !toggleMenu"></i>
      </button>

      <div class="menu-list-mobile">
        <div
          class="dropdown"
          style="padding: 1em; text-transform: uppercase"
          v-if="this.$store.state.user"
        >
          <div @click="mobileMenu = !mobileMenu" class="list-menu-nav">
            <a href="#"
              ><span>{{ this.$store.state.user.nom }} </span></a
            >
            <i class="bi bi-person-circle mx-2"></i>
            <i class="bi bi-chevron-down mx-1" v-if="!mobileMenu"></i>
            <i class="bi bi-chevron-up mx-1" v-if="mobileMenu"></i>
          </div>

          <div
            v-show="mobileMenu"
            style="
              padding: 1em;
              box-shadow: 1px 1px 1px 1px black;
              margin: 1em 0;
              background: white;
            "
            class="style_menu_lien"
          >
            <a
              href="#"
              class="d-block"
              @click.prevent="goToSpace('mobile', '/Mon_espace')"
            >
              <i class="bi bi-person-square mx-2"></i> Mon profil</a
            >
            <!-- <a
              href="#"
              class="d-block"
              @click.prevent="goToSpace('mobile', '/conversation')"
            >
              <i class="bi bi-calendar-event mx-2"></i>Conversation</a
            > -->
            <a
              href="#"
              class="d-block"
              @click.prevent="goToSpace('mobile', '/forum')"
            >
              <i class="bi bi-person-video2 mx-2"></i>Forum</a
            >
            <a href="#" class="d-block my-2" @click.prevent="DeconnecterMobile"
              ><i class="bi bi-box-arrow-right mx-2"></i>Se déconnecter</a
            >
          </div>
        </div>

        <router-link @click.prevent="toogleMyMenu" class="menu-acueil" to="/"
          >ACCUEIL
        </router-link>
        <n-collapse arrow-placement="right">
          <n-collapse-item title="LE PARTI" name="1">
            <div class="sub-menu">
              <ul class="menu-mobile">
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/mot-president"
                    >Mot du Président
                  </router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/histoire"
                    >Histoire du PDCI
                  </router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/presidents"
                    >Les Présidents Elus
                  </router-link>
                </li>
                <!-- <li>
                  <router-link
                    @click.prevent="toogleMyMenu"
                    to="/statuts-reglements"
                    >Statuts et règlements
                  </router-link>
                </li> -->
              </ul>
            </div>
          </n-collapse-item>
          <n-collapse-item title="LA GOUVERNANCE" name="2">
            <div class="sub-menu">
              <ul class="menu-mobile">
                <li>
                  <router-link
                    @click.prevent="toogleMyMenu"
                    to="/cabinet-president"
                    >Le Cabinet du Président
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.prevent="toogleMyMenu"
                    to="/vice-presidents"
                    >Le Collège des Vices-Président
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.prevent="toogleMyMenu"
                    to="/secretariat-executif"
                    >Le Secrétariat Exécutif
                  </router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/districts"
                    >Les Hauts Représentants
                  </router-link>
                </li>
              </ul>
            </div>
          </n-collapse-item>
          <!-- <li>
            <router-link
              @click.prevent="toogleMyMenu"
              to="/secretariat-executif"
              >Le Secrétariat Exécutif
            </router-link>
          </li> -->
          <n-collapse-item
            title="LES CHANTIERS"
            name="3"
            @click.prevent="goToChantier"
          >
          </n-collapse-item>
          <n-collapse-item title="L'ACTUALITE" name="4">
            <div class="sub-menu">
              <ul class="menu-mobile">
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/actualite"
                    >L'Actualité du Parti
                  </router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/mediatheque"
                    >Médiathèque
                  </router-link>
                </li>
                <li>
                  <router-link @click.prevent="toogleMyMenu" to="/decisions"
                    >Les décisions de la Présidence
                  </router-link>
                </li>
              </ul>
            </div>
          </n-collapse-item>
        </n-collapse>
        <div>
          <button class="btn">
            <!-- <router-link
              @click.prevent="toogleMyMenu"
              class="lien-add-inscription-mobile"
              to="/inscription"
            >
              Adhérer
            </router-link> -->
            <a
              target="_blank"
              href="https://adhesion.pdcirda.ci/"
              class="lien-add-inscription-mobile"
              >Adhérer</a
            >
          </button>
          <button class="btn d-block mt-5">
            <router-link
              @click.prevent="toogleMyMenu"
              style="background: #cc9b20"
              class="lien-add-inscription-mobile"
              to="/toussurlalisteelectorale"
            >
              Tous sur la liste électorale
            </router-link>
          </button>
        </div>
      </div>
    </div>
    <header id="header" class="d-flex align-items-center">
      <div class="container-fluid d-flex align-items-center">
        <div class="logo me-auto">
          <router-link to="/"
            ><img
              src="https://go2babi.lce-ci.com/public/logo%20pdci%20(1).PNG"
              alt=""
              title="Accueil"
          /></router-link>
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <router-link to="/" @click="goToAdresse('/')">
                ACCUEIL
              </router-link>
            </li>
            <li class="dropdown">
              <a href="#"
                ><span>LE PARTI</span> <i class="bi bi-chevron-down"></i
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/mot-president"
                    @click="goToAdresse('/mot-president')"
                    >Mot du Président</router-link
                  >
                </li>
                <li>
                  <router-link to="/histoire" @click="goToAdresse('/histoire')"
                    >Histoire du PDCI</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/presidents"
                    @click="goToAdresse('/presidents')"
                    >Les Présidents Elus</router-link
                  >
                </li>
                <!-- <li>
                  <router-link to="/statuts-reglements">
                    Statuts et Règlements</router-link
                  >
                </li> -->
              </ul>
            </li>

            <li class="dropdown">
              <a href="#"
                ><span>LA GOUVERNANCE</span> <i class="bi bi-chevron-down"></i
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/cabinet-president"
                    @click="goToAdresse('/cabinet-president')"
                  >
                    Le Cabinet du Président</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/vice-presidents"
                    @click="goToAdresse('/vice-presidents')"
                    >Le Collège des Vice-Présidents</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/secretariat-executif"
                    @click="goToAdresse('/secretariat-executif')"
                  >
                    Le Secrétariat Exécutif</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/districts"
                    @click="goToAdresse('/districts')"
                  >
                    Les Hauts Représentants</router-link
                  >
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <router-link to="/chantier"
                ><span>LES CHANTIERS</span> <i class="bi bi-chevron-down"></i
              ></router-link>
              <ul>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-1')"
                    >Restructuration et modernisation</router-link
                  >
                </li>

                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-2')"
                    >Redynamisation du parti</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-3')"
                    >Renforcement de l'ancrage</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-4')"
                    >Préparation des élections</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-5')"
                    >Sytème de communication</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-6')"
                    >Partenariats politiques</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chantier"
                    @click="goToChantierParam('pilier-7')"
                    >PDCI-RDA à l'international</router-link
                  >
                </li>
              </ul>
            </li>

            <!-- <li class="">
              <router-link to="/chantier"
                ><span>LES CHANTIERS</span> 
                <i class="bi bi-chevron-down"></i>
            </router-link>
            </li> -->

            <li class="dropdown">
              <a href="#"
                ><span>L'ACTUALITE</span><i class="bi bi-chevron-down"></i
              ></a>
              <ul>
                <li>
                  <router-link to="/actualite"
                    >L'Actualité du Parti</router-link
                  >
                </li>
                <li>
                  <router-link to="/mediatheque">Médiathèque </router-link>
                </li>
                <li>
                  <router-link to="/evenement">Evénements</router-link>
                </li>

                <li>
                  <router-link to="/decisions"
                    >Les décisions de la Présidence</router-link
                  >
                </li>
                <!-- <li>
                  <router-link to="/blog">Blog</router-link>
                </li> -->
              </ul>
            </li>
            <!-- <li class="dropdown">
              <a href="#"
                ><span>Blog</span><i class="bi bi-chevron-down"></i
              ></a>
            </li> -->

            <li class="btn-adherer">
              <button class="btn" style="margin-left: 15px">
                <a
                  target="_blank"
                  href="https://adhesion.pdcirda.ci/"
                  class="lien-add-inscription"
                  >Adhérer</a
                >
              </button>
            </li>
            <li class="btn-adherer">
              <button
                class="btn"
                style="margin-left: 15px; background: #cc9b20"
              >
                <router-link
                  class="lien-add-inscription"
                  to="/toussurlalisteelectorale"
                  >Tous sur la liste électorale</router-link
                >
              </button>
            </li>
            <!-- <li class="dropdown" v-if="!this.$store.state.user">
              <a href="#"
                ><span>ESPACE</span> <i class="bi bi-chevron-down"></i
              ></a>
              <ul>
                <li class="btn-adherer">
                  <button class="btn" style="margin-left: 15px">
                    <router-link class="lien-add-inscription" to="/inscription"
                      >Adhérer</router-link
                    >
                  </button>
                </li>

                <li class="btn-adherer">
                  <button class="btn" style="margin-left: 15px">
                    <router-link class="lien-add-inscription" to="/login"
                      >Se connecter</router-link
                    >
                  </button>
                </li>
              </ul>
            </li> -->
            <li
              class="dropdown d-flex align-items-center"
              v-if="this.$store.state.user"
            >
              <i class="bi bi-person-circle icone-circle"></i>
              <a href="#"
                ><span>{{ this.$store.state.user.nom }}</span></a
              >
              <ul>
                <li class="text-start">
                  <a
                    href="#"
                    class="d-block"
                    @click.prevent="goToSpace('web', '/Mon_espace')"
                  >
                    <i class="bi bi-person-square mx-2"></i> Mon profil</a
                  >
                  <!-- <a
                    href="#"
                    class="d-block"
                    @click.prevent="goToSpace('web', '/conversation')"
                  >
                    <i class="bi bi-calendar-event mx-2"></i>Conversation</a
                  > -->
                  <a
                    href="#"
                    class="d-block"
                    @click.prevent="goToSpace('web', '/forum')"
                  >
                    <i class="bi bi-person-video2 mx-2"></i>Forum</a
                  >
                  <a href="#" class="d-block" @click.prevent="Deconnecter"
                    ><i class="bi bi-box-arrow-right mx-1"></i> Se
                    déconnecter</a
                  >
                </li>
              </ul>
            </li>
          </ul>
          <i
            class="bi bi-list mobile-nav-toggle"
            @click.prevent="toggleMenu = !toggleMenu"
          ></i>
        </nav>

        <select v-model="chooseLanguage" @change="selectLanguage" class="p-1">
          <option class="FR">FR</option>
          <option class="EN">EN</option>
        </select>
        <!-- <div class="logo me-auto conteneur-image">
          <router-link to="/"
            ><img
              src="https://go2babi.lce-ci.com/public/LOGO%20ELECTION.PNG"
              alt=""
              title="Accueil"
          /></router-link>
        </div> -->

        <!-- .navbar -->
      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: "HeaderView",
  data() {
    return {
      toggleMenu: false,
      mobileMenu: false,
      chooseLanguage: JSON.parse(localStorage.getItem("language")) || "FR",
    };
  },
  methods: {
    DeconnecterMobile() {
      this.$store.dispatch("DeconnecterUser");
      this.toogleMyMenu();
    },
    goToSpace(type, route) {
      if (type === "mobile") {
        this.$router.push(route);
        this.toogleMyMenu();
      }
      if (type === "web") {
        this.$router.push(route);
      }
    },
    Deconnecter() {
      this.$store.dispatch("DeconnecterUser");
    },
    toogleMyMenu() {
      this.toggleMenu = !this.toggleMenu;
    },
    selectLanguage() {
      this.$store.commit("TOOGLE_LANGUAGE", this.chooseLanguage);
      localStorage.setItem("language", JSON.stringify(this.chooseLanguage));
      // location.reload();
      //console.log(this.$store.state.isLanguage);
    },
    goToAdresse(chemin) {
      this.$router.push({ path: chemin });
      this.$store.commit("TOOGLE_PALLIER", "pilier-1");
    },
    goToChantier() {
      this.$router.push({ path: "/chantier" });
      this.toogleMyMenu();
      this.$store.commit("TOOGLE_PALLIER", "pilier-1");
    },
    goToChantierParamMobile() {
      this.$store.commit("TOOGLE_PALLIER", "pilier-1");
      this.toogleMyMenu();
    },
    goToChantierParam(texte) {
      this.$store.commit("TOOGLE_PALLIER", texte);
    },
  },
};
</script>
<style scoped>
.mx-5 {
  margin-right: 5rem !important;
  margin-left: 0 !important;
}
a {
  text-transform: uppercase;
}
.style_menu_lien a {
  margin: 1em;
}
.list-menu-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
* {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: #1b1b1a !important;
  font-weight: 400 !important;
}
.my-mobile-profile {
  position: absolute;
  right: 1em;
  top: 0.5em;
  z-index: 999999999;
}

.mobile-nav-toggle {
  font-size: 2em !important;
}
.menu-mobile {
  padding: 1em;
}
.menu-mobile li a {
  color: black !important;
  font-size: 1em !important;
  font-weight: 500;
  text-transform: uppercase;
}
.menu-mobile li {
  text-decoration: none;
  list-style: unset;
  list-style-type: none;
  padding: 1em 0 !important;
}
.lien-add-inscription-mobile {
  width: auto !important;
  height: auto !important;
  padding: 1em 2em !important;
  background: green;
  color: white !important;
  border-radius: 15px;
}
.logo-new {
  margin: 0 1em !important;
}
.sub-menu {
  margin: 0 auto !important;
  padding: 1em !important;
  width: 90%;
  height: auto;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.menu-acueil {
  font-size: 1.3em !important;
  padding: 10px 20px !important;
  margin: 3em 0 !important;
  font-weight: bold;
}
.menu-list-mobile {
  background: white;
  margin: 4em 1em;
  padding: 1em;
  text-align: left;
  height: 90vh;
  overflow: auto;
  position: relative;
}
.bi-x-lg {
  font-size: 2em !important;
  font-weight: bold !important;
  background: white;
}
.btn-close {
  background: transparent !important;
  color: white !important;
  font-weight: bold !important;
  position: absolute !important;
  right: 2.5em !important;
  z-index: 999999 !important;
}
.list-menu {
  width: 100%;
  height: 100%;
  background: rgba(31, 53, 72, 0.9);
  position: fixed;
  z-index: 9999;
}
.conteneur-image {
  margin: 0 !important;
  position: absolute;
  right: 0;
}

.icone-circle {
  position: absolute;
  right: -1.5em;
}
</style>
