<template>
  <div v-show="this.$store.state.isLanguage === 'FR'">
    <div class="mymodal" id="myModal" v-if="toogleFramework">
      <div
        class="close-modal"
        @click.prevent="toogleFramework = !toogleFramework"
      ></div>
      <div class="modal-content">
        <div class="row">
          <div class="fermer-modal-accueil">
            <span
              class="close"
              @click.prevent="toogleFramework = !toogleFramework"
              >&times;</span
            >
          </div>

          <div class="col-lg-12 col-md-5">
            <router-link
              to="/toussurlalisteelectorale"
              alt="Tous sur la liste electorale"
            >
              <img
                src="/assets/img/tous-sur-la-liste-electorale.png"
                alt="faire-part HKB"
                width="100%"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <section id="hero" v-if="1234 > this.largeurAppareil">
      <div class="hero-container">
        <vueper-slides
          autoplay
          bullets-outside
          :dragging-distance="2"
          fixed-height="560px"
        >
          <vueper-slide
            v-for="(slide, i) in slidesFRMobile"
            :key="i"
            :image="slide.image"
            :title="slide.title"
            :content="slide.content"
          />
          <template #content>
            <div class="carousel-inner conteneur-ecriteau" role="listbox">
              <div class="carousel-content container">
                <p class="animate__animated animate__fadeInUp title-slide">
                  {{ slide.title }}
                </p>
                <h5 class="animate__animated animate__fadeInDown">
                  {{ slide.content }}
                </h5>
              </div>
            </div>
          </template>
        </vueper-slides>
      </div>
    </section>
    <section id="hero" v-else>
      <div class="hero-container">
        <vueper-slides
          autoplay
          bullets-outside
          :dragging-distance="50"
          fixed-height="850px"
        >
          <vueper-slide
            v-for="(slide, i) in slidesFR"
            :key="i"
            :image="slide.image"
            :title="slide.title"
            :content="slide.content"
            :video="slide.video"
          />
          <template #content>
            <div class="carousel-inner conteneur-ecriteau" role="listbox">
              <div class="carousel-content container">
                <p class="animate__animated animate__fadeInUp title-slide">
                  {{ slide.title }}
                </p>
                <h5 class="animate__animated animate__fadeInDown">
                  {{ slide.content }}
                </h5>
              </div>
            </div>
          </template>
        </vueper-slides>
      </div>
    </section>

    <main id="main">
      <!-- ======= About Us Section ======= -->
      <section id="about" class="about">
        <div class="container-fluid conteneur-put-white" data-aos="fade-up">
          <div class="row justify-content-center conteneur-put-white">
            <div class="col-lg-8 px-0" style="background-color: white">
              <div
                class="row no-gutters justify-content-center"
                style="background: white"
              >
                <div class="col-lg-6 p-0">
                  <img
                    src="assets/img/team/tidjane-thiam-president-du-pdci-rda.png"
                    class="img-fluid"
                    alt="Pdci"
                  />
                </div>

                <div
                  class="col-lg-6 d-flex flex-column justify-content-center about-content"
                >
                  <div class="section-title">
                    <h2>Mot du Président du PDCI-RDA</h2>
                    <p style="text-align: justify">
                      Depuis 1946 notre parti, le PDCI RDA, a été fidèle à sa
                      volonté de servir le vaillant peuple ivoirien. Malgré les
                      artefacts nés des nombreuses crises que notre pays a
                      connues, nous avons toujours su relever dignement le défi,
                      du dialogue et de la paix, des vertus chères à notre parti
                      depuis le temps des pères fondateurs<router-link
                        to="mot-president"
                        >...</router-link
                      >
                    </p>
                    <p style="text-align: justify">
                      <strong>Tidjane THIAM</strong><br />
                      <span>Président du PDCI-RDA</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End About Us Section -->

      <!-- ======= Our Portfolio Section ======= -->
      <section id="portfolio" class="portfolio section-bg">
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="section-title">
            <h2>
              <strong>Nous sommes</strong>
              <span class="mx-2 broken-parti"
                >le Parti Démocratique de Côte d'Ivoire - PDCI</span
              >
            </h2>
            <p style="text-align: center">
              Le PDCI, notre parti, a été créé en 1946 pour, comme l'indique son
              hymne,<br />
              <strong>« servir le vaillant peuple ivoirien ».</strong> Près de
              huit décennies plus tard, c'est cette <br />
              ambition, écourtée par le coup d'état de 1999, qui est au cœur de
              notre projet : <br />
              unifier, dynamiser et moderniser un parti conquérant !
            </p>
          </div>
          <VideoPresident
            v-if="this.$store.state.isLanguage === 'FR'"
            :lien="'https://www.youtube.com/embed/cD8BTpGtn68'"
            :isImage="true"
          />
        </div>
      </section>

      <section
        id="chantiers"
        class="chantiers"
        style="
          background-image: url(assets/img/bg-7-chantiers-du-programme.png);
        "
      >
        <div class="container" v-if="this.largeurAppareil > 900">
          <div class="section-title">
            <h2>Découvrez les <br />7 Piliers de notre programme</h2>
          </div>
          <n-carousel
            autoplay
            dot-placement="right"
            :slides-per-view="3"
            :space-between="10"
            draggable
          >
            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-1-restructurer-et-moderniser-le-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Restructurer <br />et moderniser le Parti
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-2-assurer-le-dynamisme-interne-et-la-vitalité-du-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Assurer le dynamisme interne et la vitalité du Parti
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-3-renforcer-l-ancrage-territorial-et-social-du-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Renforcer l'ancrage territorial et social du parti
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-4-mettre-en-place-un-systeme-de-communication-efficace.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Un dispositif performant pour les élections et les élus
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-5-mettre-en-place-un-dispositif-performant-de-préparation-des-elections.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Un système de communication efficace
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-6-batir-des-partenariats-pertinents-avec-les-autres-acteurs-politiques.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Des partenariats politiques pertinents</router-link
                  >
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-7-repositionner-le-pdci-rda-sur-l-échiquier-international.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Repositionner le PDCI-RDA sur l'échiquier international
                  </router-link>
                </h3>
              </div>
            </div>
          </n-carousel>
          <!-- <div class="row">
            <div class="col-12">
              <div class="section-title">
                <h2>Découvrez les <br />7 Piliers de notre programme</h2>
              </div>
              <div class="owl-carousel owl-theme owl-chantiers">
                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-1-restructurer-et-moderniser-le-parti.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Restructurer <br />et moderniser le Parti
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-2-assurer-le-dynamisme-interne-et-la-vitalité-du-parti.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Assurer le dynamisme interne et la vitalité du Parti
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-3-renforcer-l-ancrage-territorial-et-social-du-parti.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Renforcer l'ancrage territorial et social du parti
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-4-mettre-en-place-un-systeme-de-communication-efficace.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Un dispositif performant pour les élections et les élus
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-5-mettre-en-place-un-dispositif-performant-de-préparation-des-elections.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Un système de communication efficace
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-6-batir-des-partenariats-pertinents-avec-les-autres-acteurs-politiques.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Des partenariats politiques pertinents</router-link
                      >
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-7-repositionner-le-pdci-rda-sur-l-échiquier-international.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Repositionner le PDCI-RDA sur l'échiquier international
                      </router-link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="container" v-if="this.largeurAppareil < 900">
          <div class="section-title">
            <h2>Découvrez les <br />7 Piliers de notre programme</h2>
          </div>
          <n-carousel
            autoplay
            dot-placement="right"
            :slides-per-view="1"
            :space-between="10"
            draggable
          >
            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-1-restructurer-et-moderniser-le-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Restructurer <br />et moderniser le Parti
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-2-assurer-le-dynamisme-interne-et-la-vitalité-du-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Assurer le dynamisme interne et la vitalité du Parti
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-3-renforcer-l-ancrage-territorial-et-social-du-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Renforcer l'ancrage territorial et social du parti
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-4-mettre-en-place-un-systeme-de-communication-efficace.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Un dispositif performant pour les élections et les élus
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-5-mettre-en-place-un-dispositif-performant-de-préparation-des-elections.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Un système de communication efficace
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-6-batir-des-partenariats-pertinents-avec-les-autres-acteurs-politiques.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Des partenariats politiques pertinents</router-link
                  >
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-7-repositionner-le-pdci-rda-sur-l-échiquier-international.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Repositionner le PDCI-RDA sur l'échiquier international
                  </router-link>
                </h3>
              </div>
            </div>
          </n-carousel>
        </div>
      </section>
      <!-- End our Chantiers section -->
      <!-- ======= VALEURS Section ======= -->
      <section class="valeurs">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center marginbot30">
            <div class="col-lg-5 mb-5">
              <div class="section-title">
                <img
                  src="assets/img/carte-ancrage-du-pdci-rda-sur-le-territoire-national-ivoirien.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div class="col-lg-5" style="margin-top: 5rem">
              <div class="section-title">
                <h2>
                  <strong>Servir le vaillant</strong><br />
                  Peuple ivoirien
                </h2>

                <p style="padding: 0 50px; text-align: justify">
                  Notre ambition forte est de servir le pays en le hissant au
                  niveau de son potentiel. Il en est de notre devoir. C'est
                  pourquoi notre parti se donne les moyens de couvrir toute
                  l'étendue du territoire national pour être à l'écoute du
                  peuple ivoirien, faire un état des lieux des principaux
                  secteurs de la vie nationale.
                </p>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10">
              <div class="section-title">
                <h2><strong>Nos valeurs </strong> influencent notre vision</h2>
                <p style="text-align: justify">
                  Porteur d'une tradition de leadership, d'unité et de progrès,
                  le PDCI-RDA incarne l'essence même de l'engagement pour une
                  Côte d'Ivoire forte, unie et prospère.
                </p>
              </div>
              <div class="container">
                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Dialogue.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Dialogue</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Union.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Union</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Responsabilite.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Responsabilité</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Paix.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Paix</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Loyaute.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Loyauté</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- ======= PRESIDENT Section ======= -->
      <section class="president">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center">
            <div
              class="col-lg-10 px-0 parallax-section"
              style="
                background-image: url('assets/img/President-PDCI-RDA-Presentation-Sur-Fond-Assombri.png');
              "
            >
              <div class="row no-gutters justify-content-center">
                <div class="container">
                  <div class="row">
                    <div class="col-md no-parallax" style="display: none">
                      <img
                        src="assets/img/President-PDCI-RDA-Presentation-Sur-Fond-Assombri.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>

                    <div
                      class="col-lg-5"
                      style="background-color: white; margin-left: 5%"
                    >
                      <div class="section-title" style="text-align: justify">
                        <h2>Tidjane Thiam</h2>
                        <h3 class="marginbot30">Président du PDCI-RDA</h3>
                        <p class="marginbot30">
                          "Il n'est de richesse que d'hommes. C'est le cœur de
                          ma philosophie. Je crois que le talent est
                          uniformément réparti".
                        </p>
                        <p class="marginbot30">
                          "Les gens talentueux, il y en a dans tous les groupes,
                          dans toutes les géographies, dans toutes les ethnies,
                          dans tous les peuples, sur tous les continents. Ce qui
                          est inégalement réparti, ce sont les opportunités. Il
                          faut que chacun sente qu'il participe à quelque chose
                          de motivant".
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- ======= BUREAU EXECUTIF Section ======= -->
      <section id="be" class="be">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div class="col-lg-6 px-0 d-flex flex-column justify-content">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Prof-Philippe-Cowppli-Bony-President-Honoraire-PDCI-RDA.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Prof. Philippe COWPPLI-BONI</h2>
                    <h3>Président Honoraire du pdci-rda</h3>
                    <p style="text-align: justify">
                      "Nous avons le devoir de sauver notre pays en détresse
                      depuis plus de 20 ans. L'histoire du PDCI-RDA est jalonnée
                      de grands et rudes combats. Ce sont des combats pour
                      servir et non pour se servir".
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Noël AKOSSI BENDJO</h2>
                    <h3>Vice-président, <br />Coordonnateur Général</h3>
                    <p style="text-align: justify">
                      "Seul, nous sommes une goutte d'eau mais ensemble, nous
                      sommes un océan. Construisons cet océan qui nous permet de
                      battre tous nos adversaires et donner la chance à la Côte
                      d'Ivoire de retrouver le chemin, non seulement de la
                      réconciliation, mais d'un développement".
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Noel-Akossi-Bendjo-Vice-President-Coordonnateur-General-PDCI-RDA.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Dr-Sylvestre-Emmou-Secretaire-executif-chef-du-Secretariat-Executif-PDCI-RDA.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Dr. Sylvestre EMMOU</h2>
                    <h3>
                      Secrétaire Exécutif, <br />chef du Secrétariat Exécutif
                    </h3>
                    <p style="text-align: justify">
                      "Tous ensemble, nous continuerons de faire fonctionner au
                      mieux le PDCI-RDA en vue de la reconquête du pouvoir
                      d'Etat".
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Emile Constant Bombet</h2>
                    <h3>coordonnateur <br />des vice-présidents</h3>
                    <p style="text-align: justify">
                      "La réflexion et l'action ont toujours été au coeur de
                      notre projet politique et de notre parti. Prendre
                      conscience des problèmes à résoudre et remettre débout
                      notre parti pour mener une marche triomphante vers des
                      échéances électorales futures".
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA.png"
                      class="img-fluid"
                      alt="Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA.png"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--
           <div class="row justify-content-center marginbot30">
            <div class="col-lg-5">
              <div class="row justify-content-center">
      
                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img src="assets/img/team/Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA.png" class="img-fluid" alt="Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA" width="100%">
                  </div>
                </div>
      
                <div class="col-lg-6 d-flex flex-column justify-content-center be-bg">
                  <div class="section-title">
                    <h2 class="mt-10">Emile Constant Bombet</h2>
                    <h3>coordonnateur <br/>des vice-présidents</h3>
                    <p>La réflexion et l'action ont toujours été au coeur de notre projet politique et de notre parti. Prendre conscience des problèmes à résoudre et remettre débout notre parti pour mener une marche triomphante vers des échéances électorales futures.
                    </p>
                  </div>
                </div>
      
              </div>
            </div>
      
            <div class="col-lg-5">
              <div class="row justify-content-center">
      
                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img src="assets/img/team/Georges-Philippe-Ezaley-vice-coordonnateur-des-vice-presidents-PDCI-RDA.png" class="img-fluid" alt="Georges-Philippe-Ezaley-vice-coordonnateur-des-vice-presidents-PDCI-RDA" width="100%">
                  </div>
                </div>
      
                <div class="col-lg-6 d-flex flex-column justify-content-center be-bg">
                  <div class="section-title">
                    <h2>Georges Philippe Ezaley </h2>
                    <h3>vice-coordonnateur <br/>des vice-présidents</h3>
                    <p>Le PDCI est vivant ! <br/>Le PDCI-RDA est l'oeuvre de toute une vie du père fondateur Felix Houphouet Boigny. C'est un parti fondé pour servir le vaillant peuple ivoirien... et il vivra.
                    </p>
                  </div>
                </div>
      
              </div>
            </div>
          </div>
      
        -->

          <!--
          <div class="row">
            <div class="col-lg-12 justify-content-center" style="text-align: center;">
                  <div class=""  style="text-align: center;">
                    <button class="btn" style=""> Voir toute l'équipe dirigeante </button>
      
                  </div>
                </div>
          </div>
          -->
        </div>
      </section>
      <!-- End About Us Section -->
    </main>
  </div>
  <div v-show="this.$store.state.isLanguage === 'EN'">
    <div class="mymodal" id="myModal" v-if="toogleFramework">
      <div
        class="close-modal"
        @click.prevent="toogleFramework = !toogleFramework"
      ></div>
      <div class="modal-content">
        <div class="row">
          <div class="fermer-modal-accueil">
            <span
              class="close"
              @click.prevent="toogleFramework = !toogleFramework"
              >&times;</span
            >
          </div>

          <div class="col-lg-12 col-md-5">
            <router-link
              to="/toussurlalisteelectorale"
              alt="Tous sur la liste electorale"
            >
              <img
                src="/assets/img/tous-sur-la-liste-electorale.png"
                alt="faire-part HKB"
                width="100%"
              />
            </router-link>
          </div>
        </div>
      </div>
      <!-- <div class="modal-content">
        <div class="row justify-content-center">
          <span
            class="close"
            @click.prevent="toogleFramework = !toogleFramework"
            >&times;</span
          >

          <div class="col-lg-7 col-md-5">
            <img
              src="assets/img/faire-part_HKB-1.png"
              alt="faire-part HKB"
              width="100%"
            />
          </div>

          <div class="col-lg-5 col-md-5">
            <div class="justify-content-center">
              <h3 style="text-align: center">
                PDCI-RDA President, Mr. Tidjane Thiam says
                <strong>Yako!</strong> to the entire BÉDIÉ family, to the
                activists and supporters of the PDCI-RDA and to the entire
                Ivorian nation.
              </h3>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <section id="hero" v-if="1234 > this.largeurAppareil">
      <div class="hero-container">
        <vueper-slides
          autoplay
          bullets-outside
          :dragging-distance="2"
          fixed-height="560px"
        >
          <vueper-slide
            v-for="(slide, i) in slidesENMobile"
            :key="i"
            :image="slide.image"
            :title="slide.title"
            :content="slide.content"
          />
          <template #content>
            <div class="carousel-inner conteneur-ecriteau" role="listbox">
              <div class="carousel-content container">
                <p class="animate__animated animate__fadeInUp title-slide">
                  {{ slide.title }}
                </p>
                <h5 class="animate__animated animate__fadeInDown">
                  {{ slide.content }}
                </h5>
              </div>
            </div>
          </template>
        </vueper-slides>
      </div>
    </section>
    <section id="hero" v-else>
      <div class="hero-container">
        <vueper-slides
          autoplay
          bullets-outside
          :dragging-distance="50"
          fixed-height="850px"
        >
          <vueper-slide
            v-for="(slide, i) in slidesEN"
            :key="i"
            :image="slide.image"
            :title="slide.title"
            :content="slide.content"
          />
          <template #content>
            <div class="carousel-inner conteneur-ecriteau" role="listbox">
              <div class="carousel-content container">
                <p class="animate__animated animate__fadeInUp title-slide">
                  {{ slide.title }}
                </p>
                <h5 class="animate__animated animate__fadeInDown">
                  {{ slide.content }}
                </h5>
              </div>
            </div>
          </template>
        </vueper-slides>
      </div>
    </section>

    <main id="main">
      <!-- ======= About Us Section ======= -->
      <section id="about" class="about">
        <div class="container-fluid conteneur-put-white" data-aos="fade-up">
          <div class="row justify-content-center conteneur-put-white">
            <div class="col-lg-8 px-0" style="background-color: white">
              <div
                class="row no-gutters justify-content-center"
                style="background: white"
              >
                <div class="col-lg-6 p-0">
                  <img
                    src="assets/img/team/tidjane-thiam-president-du-pdci-rda.png"
                    class="img-fluid"
                    alt="Pdci"
                  />
                </div>

                <div
                  class="col-lg-6 d-flex flex-column justify-content-center about-content"
                >
                  <div class="section-title">
                    <h2>Message from the President of the PDCI-RDA</h2>
                    <p>
                      Since 1946 our party, the PDCI RDA, has been faithful to
                      its will to serve the valiant Ivorian people. Despite the
                      artifacts born of the many crises that our country has
                      experienced, we have always met the challenge of dialogue
                      and peace, virtues dear to our party since the time of the
                      founding fathers<router-link to="/mot-president"
                        >...</router-link
                      >
                    </p>
                    <p>
                      <strong>Tidjane THIAM</strong><br />
                      <span>President of the PDCI-RDA</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End About Us Section -->

      <!-- ======= Our Portfolio Section ======= -->
      <section id="portfolio" class="portfolio section-bg">
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="section-title">
            <h2>
              <strong>We are</strong>
              <span class="mx-2 broken-parti"
                >the Democratic Party of Côte d'Ivoire - PDCI</span
              >
            </h2>
            <p style="text-align: center">
              Our party, the PDCI, was established in 1946, as its anthem
              states,<br />
              <strong>“to serve the valiant people of Côte d'Ivoire”...</strong>
              Nearly eight decades later <br />
              this ambition, shortened by the 1999 putsch, remains at the heart
              of our project:

              <br />
              Unify, Energize and Modernize a Victorious party!
            </p>
          </div>
          <VideoPresident
            v-if="this.$store.state.isLanguage === 'EN'"
            :lien="'https://www.youtube.com/embed/cD8BTpGtn68'"
            :isImage="true"
          />
        </div>
      </section>
      <!-- End Our Portfolio Section -->

      <!-- ======= Our chantiers Section ======= -->

      <section
        id="chantiers"
        class="chantiers"
        style="
          background-image: url(assets/img/bg-7-chantiers-du-programme.png);
        "
      >
        <div class="container" v-if="this.largeurAppareil > 900">
          <div class="section-title">
            <h2>Discover the <br />7 Pillars of our project</h2>
          </div>
          <n-carousel
            autoplay
            dot-placement="right"
            :slides-per-view="3"
            :space-between="10"
            draggable
          >
            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-1-restructurer-et-moderniser-le-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Restructure <br />
                    and modernize the Party
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-2-assurer-le-dynamisme-interne-et-la-vitalité-du-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Ensure internal dynamism and vitality of the Party
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-3-renforcer-l-ancrage-territorial-et-social-du-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Strengthen the party's territorial and social anchorage
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-4-mettre-en-place-un-systeme-de-communication-efficace.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Efficient system for elections and elected officials
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-5-mettre-en-place-un-dispositif-performant-de-préparation-des-elections.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Effective communication system
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-6-batir-des-partenariats-pertinents-avec-les-autres-acteurs-politiques.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Relevant political partnerships</router-link
                  >
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-7-repositionner-le-pdci-rda-sur-l-échiquier-international.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Reposition PDCI-RDA in the international arena as a global
                    leader.
                  </router-link>
                </h3>
              </div>
            </div>
          </n-carousel>
        </div>

        <div class="container" v-if="this.largeurAppareil < 900">
          <div class="section-title">
            <h2>Discover the <br />7 Pillars of our project</h2>
          </div>
          <n-carousel
            autoplay
            dot-placement="right"
            :slides-per-view="1"
            :space-between="10"
            draggable
          >
            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-1-restructurer-et-moderniser-le-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Restructure <br />
                    and modernize the Party
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-2-assurer-le-dynamisme-interne-et-la-vitalité-du-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Ensure internal dynamism and vitality of the Party
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-3-renforcer-l-ancrage-territorial-et-social-du-parti.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Strengthen the party's territorial and social anchorage
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-4-mettre-en-place-un-systeme-de-communication-efficace.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    An efficient system for elections and elected officials
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-5-mettre-en-place-un-dispositif-performant-de-préparation-des-elections.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    An effective communication system
                  </router-link>
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-6-batir-des-partenariats-pertinents-avec-les-autres-acteurs-politiques.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Relevant political partnerships</router-link
                  >
                </h3>
              </div>
            </div>

            <div class="carousel-img">
              <div class="boxChantiers">
                <div class="icon-circle">
                  <img
                    src="assets/img/icons/icone-chantier-7-repositionner-le-pdci-rda-sur-l-échiquier-international.png"
                    alt="Icone 1"
                  />
                </div>
                <h3>
                  <router-link to="/chantier">
                    Reposition PDCI-RDA in the international arena.
                  </router-link>
                </h3>
              </div>
            </div>
          </n-carousel>
        </div>
      </section>
      <!-- End our Chantiers section -->
      <!-- ======= VALEURS Section ======= -->
      <section class="valeurs">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center marginbot30">
            <div class="col-lg-5 mb-5">
              <div class="section-title">
                <img
                  src="assets/img/carte-ancrage-du-pdci-rda-sur-le-territoire-national-ivoirien.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div class="col-lg-5" style="margin-top: 5rem">
              <div class="section-title">
                <h2>
                  <strong>Serving the valiant</strong><br />
                  Ivorian people
                </h2>

                <p style="padding: 0 50px; text-align: justify">
                  Our strong ambition is to serve the country to reach its
                  fullest potential. It is our duty. This is why our party is
                  dedicating itself to cover the entire national territory to be
                  attentive to the Ivorian people, to make an inventory of the
                  main sectors of national life.
                </p>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10">
              <div class="section-title">
                <h2>Our values influence our vision</h2>
                <p style="text-align: justify">
                  The commitment to a stronger, united, and prosperous Côte
                  d'Ivoire is embodied in the PDCI-RDA, which has a long
                  tradition of leadership, unity, and progress.
                </p>
              </div>
              <div class="container">
                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Dialogue.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Dialogue</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Union.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Union</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Responsabilite.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Responsibility</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Paix.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Peace</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Loyaute.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Loyalty</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- ======= PRESIDENT Section ======= -->
      <section class="president">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center">
            <div
              class="col-lg-10 px-0 parallax-section"
              style="
                background-image: url('assets/img/President-PDCI-RDA-Presentation-Sur-Fond-Assombri.png');
              "
            >
              <div class="row no-gutters justify-content-center">
                <div class="container">
                  <div class="row">
                    <div class="col-md no-parallax" style="display: none">
                      <img
                        src="assets/img/President-PDCI-RDA-Presentation-Sur-Fond-Assombri.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>

                    <div
                      class="col-lg-5"
                      style="background-color: white; margin-left: 5%"
                    >
                      <div class="section-title" style="text-align: justify">
                        <h2>Tidjane Thiam</h2>
                        <h3 class="marginbot30">President of the PDCI-RDA</h3>
                        <p class="marginbot30">
                          “There is wealth only of men. This is the heart of my
                          philosophy. I believe that talent is evenly
                          distributed.”
                        </p>
                        <p class="marginbot30">
                          “There are talented people in every group, in every
                          geographical area, in every ethnicity, in every
                          people, on every continent. What is unevenly
                          distributed are opportunities. Everyone must feel that
                          they are participating in something that is
                          motivating.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- ======= BUREAU EXECUTIF Section ======= -->
      <section id="be" class="be">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div class="col-lg-6 px-0 d-flex flex-column justify-content">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Prof-Philippe-Cowppli-Bony-President-Honoraire-PDCI-RDA.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Prof. Philippe COWPPLI-BONI</h2>
                    <h3>Honorary President of the pdci-rda</h3>
                    <p>
                      “We have a duty to save our country that has been in
                      distress for more than 20 years. The history of the
                      PDCI-RDA is marked by major harsh battles. These are
                      fights to serve, not to serve ourselves.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Christmas AKOSSI BENDJO</h2>
                    <h3>Vice President, <br />General Coordinator</h3>
                    <p>
                      “Alone, we are a drop in the bucket, but together, we are
                      an ocean. Let’s build this ocean that allows us to beat
                      all our opponents and give Côte d'Ivoire the chance to
                      find the path back, not only of reconciliation, but of
                      development.”
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Noel-Akossi-Bendjo-Vice-President-Coordonnateur-General-PDCI-RDA.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Dr-Sylvestre-Emmou-Secretaire-executif-chef-du-Secretariat-Executif-PDCI-RDA.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Dr. Sylvestre EMMOU</h2>
                    <h3>
                      Executive Secretary, <br />head of the Executive
                      Secretariat
                    </h3>
                    <p>
                      “Together, we will continue to make the PDCI-RDA work as
                      best we can to regain state power.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Emile Constant Bombet</h2>
                    <h3>coordinator <br />of vice-presidents</h3>
                    <p>
                      “Reflection and action have always been at the heart of
                      our political focus/agenda and our party. To become aware
                      of the problems to be solved and to put our party back on
                      track to lead a triumphant march towards future
                      elections.”
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA.png"
                      class="img-fluid"
                      alt="Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA.png"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--
           <div class="row justify-content-center marginbot30">
            <div class="col-lg-5">
              <div class="row justify-content-center">
      
                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img src="assets/img/team/Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA.png" class="img-fluid" alt="Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA" width="100%">
                  </div>
                </div>
      
                <div class="col-lg-6 d-flex flex-column justify-content-center be-bg">
                  <div class="section-title">
                    <h2 class="mt-10">Emile Constant Bombet</h2>
                    <h3>coordonnateur <br/>des vice-présidents</h3>
                    <p>La réflexion et l'action ont toujours été au coeur de notre projet politique et de notre parti. Prendre conscience des problèmes à résoudre et remettre débout notre parti pour mener une marche triomphante vers des échéances électorales futures.
                    </p>
                  </div>
                </div>
      
              </div>
            </div>
      
            <div class="col-lg-5">
              <div class="row justify-content-center">
      
                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img src="assets/img/team/Georges-Philippe-Ezaley-vice-coordonnateur-des-vice-presidents-PDCI-RDA.png" class="img-fluid" alt="Georges-Philippe-Ezaley-vice-coordonnateur-des-vice-presidents-PDCI-RDA" width="100%">
                  </div>
                </div>
      
                <div class="col-lg-6 d-flex flex-column justify-content-center be-bg">
                  <div class="section-title">
                    <h2>Georges Philippe Ezaley </h2>
                    <h3>vice-coordonnateur <br/>des vice-présidents</h3>
                    <p>Le PDCI est vivant ! <br/>Le PDCI-RDA est l'oeuvre de toute une vie du père fondateur Felix Houphouet Boigny. C'est un parti fondé pour servir le vaillant peuple ivoirien... et il vivra.
                    </p>
                  </div>
                </div>
      
              </div>
            </div>
          </div>
      
        -->

          <!--
          <div class="row">
            <div class="col-lg-12 justify-content-center" style="text-align: center;">
                  <div class=""  style="text-align: center;">
                    <button class="btn" style=""> Voir toute l'équipe dirigeante </button>
      
                  </div>
                </div>
          </div>
          -->
        </div>
      </section>
      <!-- End About Us Section -->
    </main>
  </div>
  <!-- <div v-show="this.$store.state.isLanguage === 'EN'">
    <div class="mymodal" id="myModal" v-if="toogleFramework">
      <div
        class="close-modal"
        @click.prevent="toogleFramework = !toogleFramework"
      ></div>
      <div class="modal-content">
        <div class="row">
          <span
            class="close"
            @click.prevent="toogleFramework = !toogleFramework"
            >&times;</span
          >

          <div class="col-lg-7 col-md-5">
            <img
              src="assets/img/faire-part_HKB-1.png"
              alt="faire-part HKB"
              width="100%"
            />
          </div>

          <div class="col-lg-5 col-md-5">
            <div class="justify-content-center">
              <h3 style="text-align: center">
                PDCI-RDA President, Mr. Tidjane Thiam says
                <strong>Yako!</strong> to the entire BÉDIÉ family, to the
                activists and supporters of the PDCI-RDA and to the entire
                Ivorian nation.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="hero" v-if="1234 > this.largeurAppareil">
      <div class="hero-container">
        <vueper-slides
          autoplay
          bullets-outside
          :dragging-distance="50"
          fixed-height="800px"
        >
          <vueper-slide
            v-for="(slide, i) in slidesEN"
            :key="i"
            :image="slide.image"
            :title="slide.title"
            :content="slide.content"
            :video="slide.video"
          />
          <template #content>
            <div class="carousel-inner conteneur-ecriteau" role="listbox">
              <div class="carousel-content container">
                <p class="animate__animated animate__fadeInUp title-slide">
                  {{ slide.title }}
                </p>
                <h5 class="animate__animated animate__fadeInDown">
                  {{ slide.content }}
                </h5>
              </div>
            </div>
          </template>
        </vueper-slides>
      </div>
    </section>
    <section id="hero" v-else>
      <div class="hero-container">
        <vueper-slides
          autoplay
          bullets-outside
          :dragging-distance="50"
          fixed-height="800px"
        >
          <vueper-slide
            v-for="(slide, i) in slidesEnMobile"
            :key="i"
            :image="slide.image"
            :title="slide.title"
            :content="slide.content"
            :video="slide.video"
          />
          <template #content>
            <div class="carousel-inner conteneur-ecriteau" role="listbox">
              <div class="carousel-content container">
                <p class="animate__animated animate__fadeInUp title-slide">
                  {{ slide.title }}
                </p>
                <h5 class="animate__animated animate__fadeInDown">
                  {{ slide.content }}
                </h5>
              </div>
            </div>
          </template>
        </vueper-slides>
      </div>
    </section>

    <main id="main">
      
      <section id="about" class="about">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center">
            <div class="col-lg-8 px-0" style="background-color: white">
              <div
                class="row no-gutters justify-content-center"
                style="background: white"
              >
                <div class="col-lg-6 p-0">
                  <img
                    src="assets/img/team/tidjane-thiam-president-du-pdci-rda.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>

                <div
                  class="col-lg-6 d-flex flex-column justify-content-center about-content"
                >
                  <div class="section-title" style="text-align: justify">
                    <h2>Message from the President of the PDCI-RDA</h2>
                    <p>
                      Since 1946 our party, the PDCI RDA, has been faithful to
                      its will to serve the valiant Ivorian people. Despite the
                      artifacts born of the many crises that our country has
                      experienced, we have always met the challenge of dialogue
                      and peace, virtues dear to our party since the time of the
                      founding fathers<router-link to="/mot-president"
                        >...</router-link
                      >
                    </p>
                    <p>
                      <strong>Tidjane THIAM</strong><br />
                      <span>President of the PDCI-RDA</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="portfolio" class="portfolio section-bg">
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="section-title">
            <h2>
              <strong>We are</strong>
              <span class="mx-2 broken-parti"
                >the Democratic Party of Côte d'Ivoire - PDCI</span
              >
            </h2>
            <p style="text-align: center">
              Our party, the PDCI, was established in 1946, as its anthem
              states,<br />
              <strong>“to serve the valiant people of Côte d'Ivoire”...</strong>
              Nearly eight decades later <br />
              this ambition, shortened by the 1999 putsch, remains at the heart
              of our project:

              <br />
              Unify, Energize and Modernize a Conquering party!
            </p>
          </div>

          <VideoPresident
            v-if="this.$store.state.isLanguage === 'EN'"
            :lien="'https://www.youtube.com/embed/cD8BTpGtn68'"
            :isImage="true"
          />
        </div>
      </section>

      <section
        id="chantiers2"
        class="chantiers"
        style="
          background-image: url(assets/img/bg-7-chantiers-du-programme.png);
        "
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title">
                <h2>Discover the <br />7 Pillars of our project</h2>
              </div>
              <div class="owl-carousel owl-theme owl-chantiers">
                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-1-restructurer-et-moderniser-le-parti.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Restructure <br />
                        and modernize the Party
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-2-assurer-le-dynamisme-interne-et-la-vitalité-du-parti.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Ensure internal dynamism and vitality of the Party
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-3-renforcer-l-ancrage-territorial-et-social-du-parti.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Strengthen the party's territorial and social anchorage
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-4-mettre-en-place-un-systeme-de-communication-efficace.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        An efficient system for elections and elected officials
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-5-mettre-en-place-un-dispositif-performant-de-préparation-des-elections.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        An effective communication system
                      </router-link>
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-6-batir-des-partenariats-pertinents-avec-les-autres-acteurs-politiques.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Relevant political partnerships</
                      >
                    </h3>
                  </div>
                </div>

                <div class="item">
                  <div class="boxChantiers">
                    <div class="icon-circle">
                      <img
                        src="assets/img/icons/icone-chantier-7-repositionner-le-pdci-rda-sur-l-échiquier-international.png"
                        alt="Icone 1"
                      />
                    </div>
                    <h3>
                      <router-link to="/chantier">
                        Reposition PDCI-RDA in the international arena.
                      </router-link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="valeurs">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center marginbot30">
            <div class="col-lg-5 mb-5">
              <div class="section-title">
                <img
                  src="assets/img/carte-ancrage-du-pdci-rda-sur-le-territoire-national-ivoirien.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div class="col-lg-5" style="margin-top: 5rem">
              <div class="section-title">
                <h2>
                  <strong>Serving the valiant</strong><br />
                  Ivorian people
                </h2>

                <p style="padding: 0 50px; text-align: justify">
                  Our strong ambition is to serve the country at its potential.
                  It is our duty. This is why our party is giving itself the
                  ability to cover the entire national territory to be attentive
                  to the Ivorian people, to make an inventory of the main
                  sectors of national life.
                </p>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10">
              <div class="section-title">
                <h2>Our values influence our vision</h2>
                <p style="text-align: justify">
                  The commitment to a stronger, united, and prosperous Côte
                  d'Ivoire is embodied in the PDCI-RDA, which has a long
                  tradition of leadership, unity, and progress.
                </p>
              </div>
              <div class="container">
                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Dialogue.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Dialogue</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Union.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Union</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Responsabilite.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Responsibility</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Paix.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Peace</p>
                </div>

                <div class="box-circle">
                  <img
                    src="assets/img/Valeur-PDCI-Loyaute.png"
                    alt="Image"
                    class="icon"
                  />
                  <p class="title">Loyalty</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="president">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center">
            <div
              class="col-lg-10 px-0 parallax-section"
              style="
                background-image: url('assets/img/President-PDCI-RDA-Presentation-Sur-Fond-Assombri.png');
              "
            >
              <div class="row no-gutters justify-content-center">
                <div class="container">
                  <div class="row">
                    <div class="col-md no-parallax" style="display: none">
                      <img
                        src="assets/img/President-PDCI-RDA-Presentation-Sur-Fond-Assombri.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>

                    <div
                      class="col-lg-5"
                      style="background-color: white; margin-left: 5%"
                    >
                      <div class="section-title" style="text-align: justify">
                        <h2>Tidjane Thiam</h2>
                        <h3 class="marginbot30">President of the PDCI-RDA</h3>
                        <p class="marginbot30">
                          “There is no wealth except in men. This is the heart
                          of my philosophy. I believe that talent is evenly
                          distributed.”
                        </p>
                        <p class="marginbot30">
                          “There are talented people in every group, in every
                          geography, in every ethnicity, in every people, on
                          every continent. What is unevenly distributed are
                          opportunities. Everyone must feel that they are
                          participating in something motivating.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="be" class="be">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div class="col-lg-6 px-0 d-flex flex-column justify-content">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Prof-Philippe-Cowppli-Bony-President-Honoraire-PDCI-RDA.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Prof. Philippe COWPPLI-BONI</h2>
                    <h3>Honorary President of the pdci-rda</h3>
                    <p>
                      “We have a duty to save our country in distress for more
                      than 20 years. The history of the PDCI-RDA is marked by
                      great and harsh battles. These are fights to serve, not to
                      serve ourselves.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Christmas AKOSSI BENDJO</h2>
                    <h3>Vice President, <br />General Coordinator</h3>
                    <p>
                      “Alone, we are a drop in the bucket, but together, we are
                      an ocean. Let's build this ocean that allows us to beat
                      all our opponents and give Côte d'Ivoire the chance to
                      find the path back, not only of reconciliation, but of
                      development.”
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Noel-Akossi-Bendjo-Vice-President-Coordonnateur-General-PDCI-RDA.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Dr-Sylvestre-Emmou-Secretaire-executif-chef-du-Secretariat-Executif-PDCI-RDA.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Dr. Sylvestre EMMOU</h2>
                    <h3>
                      Executive Secretary, <br />head of the Executive
                      Secretariat
                    </h3>
                    <p>
                      “Together, we will continue to make the PDCI-RDA work as
                      best we can in order to regain state power.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center marginbot30">
            <div class="col-lg-10 px-0 be-bg">
              <div class="row no-gutters justify-content-center">
                <div
                  class="col-lg-6 d-flex flex-column justify-content-center be-bg"
                >
                  <div class="section-title">
                    <h2>Emile Constant Bombet</h2>
                    <h3>coordinator <br />of vice-presidents</h3>
                    <p>
                      “Reflection and action have always been at the heart of
                      our political project and our party. To become aware of
                      the problems to be solved and to put our party back on
                      track to lead a triumphant march towards future
                      elections.”
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 px-0">
                  <div class="img-container">
                    <img
                      src="assets/img/team/Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA.png"
                      class="img-fluid"
                      alt="Emile-Constant-Bombet-Coordonnateur-des-vice-presidents-PDCI-RDA.png"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div> -->
</template>
<script>
import VideoPresident from "@/components/VideoPresident.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  name: "HomeView",
  components: {
    VueperSlides,
    VueperSlide,
    VideoPresident,
    // RightCircleOutlined,
    // LeftCircleOutlined,
  },
  data() {
    return {
      largeurAppareil: window.innerWidth,
      toogleFramework: true,
      isOpenAtChatBot: false,
      language: this.$store.state.isLanguage,
      slidesFR: [
        {
          title: "",

          content: ``,

          image: `/assets/img/slide/slider-soubre-cest-chez-nous.png`,
        },
        {
          title: "UNE SEULE MISSION",

          content: `<h2 style="width:100%;font-weight:bold" 
          class="animate__animated animate__fadeInDown text-gros">Servir le vaillant


                 <br/>peuple ivoirien <br/>
                </h2>
                 <p class="paragraphe" style="font-family: Montserrat, sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;">
  DEPUIS 1946
                  </p>


`,
          image: `/assets/img/slide/slider-une-seule-mission-servir-le-vaillant-peuple-ivoirien-depuis-1946.png`,
        },
        //         {
        //           title: "LE PRÉSIDENT DU PDCI-RDA, TIDJANE THIAM",

        //           content: `<h2 style="width:100%;font-weight:bold"
        //           class="animate__animated animate__fadeInDown text-gros">dit YAKO à toute

        //                  <br/>la famille BÉDIÉ <br/>
        //                 </h2>
        //                  <p class="paragraphe" style="font-family: Montserrat, sans-serif;
        //   font-size: 16px !important;
        //   font-weight: 500 !important;">
        //                   À TOUS LES MILITANTS ET SYMPATHISANTS DU PDCI-RDA <br/>
        // ET À TOUTE LA NATION IVOIRIENNE.
        //                   </p>

        // `,
        //           image: `/assets/img/slide/yako-condoleances-du-president-tidjane-thiam-a-la-famille-bedie-au-pdci-rda-et-a-la-nation-ivoirienne.png`,
        //         },
        {
          title: "Une seule Vision",

          content: `<h2 style="width:100%;font-weight:bold" 
class="animate__animated animate__fadeInDown text-gros">Unifier, 
dynamiser et moderniser <br/> un parti conquérant</h2>`,

          image: `/assets/img/slide/slider-une-seule-vision-unifier-dynamiser-et-moderniser-un-parti-conquerant.png`,
        },
        {
          title: "Qui sommes-nous ?",

          content: `<h2 style="font-weight:bold"
          class="animate__animated animate__fadeInDown text-gros"
          >Nous sommes le parti <br/>démorcratique de Côte d'Ivoire</h2>`,

          image: `/assets/img/slide/slider-qui-sommes-nous-nous-sommes-le-parti-democratique-de-cote-divoire-pdci.png`,
        },
        // {
        //   title: "La famille PDCI-RDA",

        //   content: `<h1 style="width:100%;font-weight:bold"
        //   >Célèbre et honore <br/>un grand homme</h1>`,

        //   image: `/assets/img/slide/slider-hommage-en-memoire-du-president-henri-konan-bedie.png`,
        // },
      ],
      slidesFRMobile: [
        {
          title: "",

          content: ``,

          image: `/assets/img/slide/slider-soubre-cest-chez-nous-m.png`,
        },
        {
          title: "UNE SEULE MISSION",

          content: `<h2 style="width:100%;font-weight:bold" 
          class="animate__animated animate__fadeInDown text-gros">Servir le vaillant


                 <br/>peuple ivoirien <br/>
                </h2>
                 <p class="paragraphe" style="font-family: Montserrat, sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;">
  DEPUIS 1946
                  </p>


`,
          image: `/assets/img/slide/slider-une-seule-mission-servir-le-vaillant-peuple-ivoirien-depuis-1946-m.png`,
        },
        //         {
        //           title: "LE PRÉSIDENT DU PDCI-RDA, TIDJANE THIAM",

        //           content: `<h2 style="width:100%;font-weight:bold"
        //           class="animate__animated animate__fadeInDown text-gros">dit YAKO à toute

        //                  <br/>la famille BÉDIÉ <br/>
        //                 </h2>
        //                  <p class="paragraphe" style="font-family: Montserrat, sans-serif;
        //   font-size: 16px !important;
        //   font-weight: 500 !important;">
        //                   À TOUS LES MILITANTS ET SYMPATHISANTS DU PDCI-RDA <br/>
        // ET À TOUTE LA NATION IVOIRIENNE.
        //                   </p>

        // `,
        //           image: `/assets/img/slide/yako-condoleances-du-president-tidjane-thiam-a-la-famille-bedie-au-pdci-rda-et-a-la-nation-ivoirienne-m.png`,
        //         },
        {
          title: "Une seule Vision",

          content: `<h2 style="width:100%;font-weight:bold" 
          class="animate__animated animate__fadeInDown text-gros">Unifier, 
          dynamiser et moderniser <br/> un parti conquérant</h2>`,

          image: `/assets/img/slide/slider-une-seule-vision-unifier-dynamiser-et-moderniser-un-parti-conquerant.png`,
        },
        {
          title: "Qui sommes-nous ?",

          content: `<h2 style="width:100%;font-weight:bold"
          class="animate__animated animate__fadeInDown text-gros"
          >Nous sommes le parti <br/>démorcratique de Côte d'Ivoire</h2>`,

          image: `/assets/img/slide/slider-qui-sommes-nous-nous-sommes-le-parti-democratique-de-cote-divoire-pdci.png`,
        },
        // {
        //   title: "La famille PDCI-RDA",

        //   content: `<h1 style="width:100%;font-weight:bold"
        //   >célèbre et honore <br/>un grand homme</h1>`,

        //   image: `/assets/img/slide/slider-hommage-en-memoire-du-president-henri-konan-bedie-m.png`,
        // },
      ],
      slidesEN: [
        {
          title: "",

          content: ``,

          image: `/assets/img/slide/slider-soubre-cest-chez-nous.png`,
        },
        {
          title: "ONLY ONE MISSION",

          content: `<h2 style="width:100%;font-weight:bold" 
          class="animate__animated animate__fadeInDown text-gros">Serve the valiant


                 <br/>Ivorian people <br/>
                </h2>
                 <p class="paragraphe" style="font-family: Montserrat, sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;">
  SINCE 1946
                  </p>


`,
          image: `/assets/img/slide/slider-une-seule-mission-servir-le-vaillant-peuple-ivoirien-depuis-1946.png`,
        },
        //         {
        //           title: "PDCI-RDA President",

        //           content: `<h2 style="width:100%;font-weight:bold"
        //           class="animate__animated animate__fadeInDown text-gros">Mr. Tidjane Thiam says Yako!

        //                  <br/>to the entire BÉDIÉ family, <br/>
        //                 </h2>
        //                  <p class="paragraphe" style="font-family: Montserrat, sans-serif;
        //   font-size: 16px !important;
        //   font-weight: 500 !important;">
        //   to the activists and supporters of the PDCI-RDA <br/>
        //   and to the entire Ivorian nation.
        //                   </p>

        // `,
        //           image: `/assets/img/slide/yako-condoleances-du-president-tidjane-thiam-a-la-famille-bedie-au-pdci-rda-et-a-la-nation-ivoirienne.png`,
        //         },
        {
          title: "One Vision",

          content: `<h2 style="width:100%;font-weight:bold"
        class="animate__animated animate__fadeInDown text-gros">Unify, energize and modernize <br/> a conquering or a Victorious party</h2>`,

          image: `/assets/img/slide/slider-une-seule-vision-unifier-dynamiser-et-moderniser-un-parti-conquerant.png`,
        },
        {
          title: "Who we are ?",

          content: `<h2 style="font-weight:bold"
       class="animate__animated animate__fadeInDown text-gros">We are the democratic <br/> party of Côte d'Ivoire</h2>`,

          image: `/assets/img/slide/slider-qui-sommes-nous-nous-sommes-le-parti-democratique-de-cote-divoire-pdci.png`,
        },
        // {
        //   title: "The PDCI-RDA family",

        //   content: `<h1 style="width:100%;font-weight:bold"
        //   >Celebrate and honor  <br/>a great man</h1>`,

        //   image: `/assets/img/slide/slider-hommage-en-memoire-du-president-henri-konan-bedie.png`,
        // },
      ],
      slidesENMobile: [
        {
          title: "",

          content: ``,

          image: `/assets/img/slide/slider-soubre-cest-chez-nous-m.png`,
        },
        {
          title: "ONLY ONE MISSION",

          content: `<h2 style="width:100%;font-weight:bold" 
          class="animate__animated animate__fadeInDown text-gros">Serve the valiant


                 <br/>Ivorian people <br/>
                </h2>
                 <p class="paragraphe" style="font-family: Montserrat, sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;">
  SINCE 1946
                  </p>


`,
          image: `/assets/img/slide/slider-une-seule-mission-servir-le-vaillant-peuple-ivoirien-depuis-1946-m.png`,
        },
        //         {
        //           title: "PDCI-RDA President",

        //           content: `<h2 style="width:100%;font-weight:bold"
        //           class="animate__animated animate__fadeInDown text-gros">Mr. Tidjane Thiam says Yako!

        //                  <br/>to the entire BÉDIÉ family, <br/>
        //                 </h2>
        //                  <p class="paragraphe" style="font-family: Montserrat, sans-serif;
        //   font-size: 16px !important;
        //   font-weight: 500 !important;">
        //   to the activists and supporters of the PDCI-RDA <br/>
        //   and to the entire Ivorian nation.
        //                   </p>

        // `,
        //           image: `/assets/img/slide/yako-condoleances-du-president-tidjane-thiam-a-la-famille-bedie-au-pdci-rda-et-a-la-nation-ivoirienne-m.png`,
        //         },
        {
          title: "One Vision",

          content: `<h2 style="width:100%;font-weight:bold"
        class="animate__animated animate__fadeInDown text-gros">Unify, energize and modernize <br/> a conquering party</h2>`,

          image: `/assets/img/slide/slider-une-seule-vision-unifier-dynamiser-et-moderniser-un-parti-conquerant.png`,
        },
        {
          title: "Who we are ?",

          content: `<h2 style="width:100%;font-weight:bold"
       class="animate__animated animate__fadeInDown text-gros">We are the democratic party of Côte d'Ivoire</h2>`,

          image: `/assets/img/slide/slider-qui-sommes-nous-nous-sommes-le-parti-democratique-de-cote-divoire-pdci.png`,
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.majLargeurAppareil);
  },
  unmounted() {
    window.removeEventListener("resize", this.majLargeurAppareil);
  },
  methods: {
    majLargeurAppareil() {
      this.largeurAppareil = window.innerWidth;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Montserrat, sans-serif !important;
}
.fermer-modal-accueil {
  display: flex;
  justify-content: flex-end;
}
.conteneur-btn-chat-bot {
  width: 50px;
  height: 50px;
  background: #128743;
  border-radius: 100%;
  color: white;
  position: fixed;
  bottom: 5em;
  right: 0.6em;
  line-height: 50px;
  z-index: 98;
  cursor: pointer;
}
.bi-robot {
  font-size: 2em !important;
}
#about {
  margin-top: -19em;
}
@media screen and (max-width: 776px) {
  #about {
    margin-top: -19em;
  }
  #main {
    margin-top: 0 !important;
  }
  .paragraphe {
    text-align: center !important;
    font-weight: bold;
  }
}
.carousel-img {
  width: 90%;
  height: 200px;
  object-fit: cover;
  background: white;
}
@media screen and (max-width: 1229px) {
  .conteneur-put-white {
    background: #f7f7f7;
  }
}
</style>
