<script>
import { onMounted, onUnmounted, onUpdated, ref } from "vue";
import instance from "@/api";
import { useStore } from "vuex";
import { Help } from "@/views/Utils";
// import Editor from "@/components/TextEditor.vue";
export default {
  name: "ConversationChatBot",
  props: ["onlineConversation", "isAssistance", "question", "isLoading"],
  // components: {
  //   Editor,
  // },
  setup(props, { emit }) {
    const handleListMessage = ref(null);
    const erroMsg = ref(false);
    const store = useStore();
    const startConversation = ref(props.onlineConversation);
    const textChatBot = ref(null);
    const pollingInterval = ref("");
    const isAssistanceChoose = ref(props.isAssistance);
    const questionChoose = ref(props.question);
    const isChargement = ref(props.isLoading);
    // const oldValue = ref("");
    const textTemporaire = ref("");
    const isLoadingDiscussion = ref(false);
    const replaceNewlinesWithBr = (texte) => {
      return texte.replace(/\n/g, "<br>");
    };
    const sendWithChatBot = async () => {
      // console.log("ID", document.getElementById("texte_area"));
      // console.log("chatBot", textChatBot.value);
      const formattedText = replaceNewlinesWithBr(textChatBot.value);
      // store.commit("ADD_QUESTION_CONVERSATION", textChatBot.value);
      if (textChatBot.value) {
        erroMsg.value = false;
        isLoadingDiscussion.value = true;
        const idChat = sessionStorage.getItem("ID_chat");
        let data = {
          texte: formattedText,
          chat_id: idChat,
        };
        textTemporaire.value = formattedText;
        // console.log("DATA", data);
        handleListMessage.value.push({
          id: new Date(),
          sender_id: store.state.user.id,
          texte: formattedText,
          created_at: new Date(),
        });
        try {
          const reponse = await instance.post("messages", data);
          console.log("REPONSE-MESSAGE2", reponse.data);
          textChatBot.value = "";
          scrollToBottom();
          isLoadingDiscussion.value = false;
        } catch (error) {
          console.log(error);
          isLoadingDiscussion.value = false;
        }
        // if (!textChatBot.value) {
        //   erroMsg.value = true;
        // } else {

        // }
      } else {
        erroMsg.value = true;
      }
    };
    const getListMessage = async () => {
      if (isAssistanceChoose.value && sessionStorage.getItem("ID_chat")) {
        try {
          const reponse = await instance.get(
            "chats/" + sessionStorage.getItem("ID_chat")
          );
          console.log("getListMessage", reponse.data);
          console.log(
            "getListMessage3",
            Help.groupBy(reponse.data.data.messages)
          );
          handleListMessage.value = reponse.data.data.messages;
          // console.log("oldValue.value", oldValue.value);
          textTemporaire.value = "";
        } catch (error) {
          console.log(error);
        }
      } else {
        return;
      }
    };
    const handleTexte = (e) => {
      console.log("handleTexte", e);
    };
    const closeConversation = async () => {
      emit("close", false);
      console.log("textChatBot.value", textChatBot.value);
      // if (!textChatBot.value) {
      //   try {
      //     const reponse = await instance.delete("chats/" + store.state.id_chat);
      //     console.log(reponse);
      //   } catch (error) {
      //     console.log(error);
      //   }
      // } else {
      //   console.log("pas delete");
      // }
    };
    const startMessagePolling = async () => {
      pollingInterval.value = setInterval(getListMessage, 10000);
    };
    const stopMessagePolling = () => {
      if (pollingInterval.value) {
        clearInterval(pollingInterval.value);
        pollingInterval.value = null;
      }
    };
    const insertLineBreak = (event) => {
      // Ajoute un retour à la ligne à la position actuelle du curseur
      // textChatBot.value += "\n";
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      textChatBot.value =
        textChatBot.value.substring(0, start) +
        "\r\n" +
        textChatBot.value.substring(end);
    };
    function scrollToBottom() {
      const container = document.getElementById("chatContainerConversation");
      container.scrollTop = container.scrollHeight;
    }
    onMounted(async () => {
      try {
        getListMessage();
        startMessagePolling();
      } catch (error) {
        console.log(error);
      }
    });
    onUpdated(() => {
      scrollToBottom();
    });
    // onBeforeUpdate(() => {
    //   console.log("nbreValueArrayonBeforeUpdate", nbreValueArray.value);
    // });
    onUnmounted(() => {
      stopMessagePolling();
    });
    return {
      handleListMessage,
      startConversation,
      sendWithChatBot,
      closeConversation,
      textChatBot,
      erroMsg,
      isAssistanceChoose,
      questionChoose,
      isChargement,
      isLoadingDiscussion,
      textTemporaire,
      insertLineBreak,
      handleTexte,
      Help,
    };
  },
};
</script>
<template>
  <div class="conteneur-conversations position-absolute">
    <div class="header d-flex justify-content-between px-4 py-2">
      <div style="text-align: left">
        <p class="text-danger" @click="closeConversation">Fermer</p>
      </div>
      <div>
        <h5>Online</h5>
      </div>
    </div>
    <div class="main" id="chatContainerConversation">
      <div v-if="handleListMessage">
        <div
          class="d-flex reponse-question"
          v-for="item in $store.state.reponseChatBot"
          :key="item.module"
        >
          <p
            class="d-flex align-self-end position-relative"
            v-if="item.module == 'Assistance'"
          >
            <span class="questions text-end mx-1">{{ item.module }}</span>
            <span class="position-absolute bottom-0 end-0">{{
              new Intl.DateTimeFormat("fr", {
                timeStyle: "short",
              }).format(new Date())
            }}</span>
          </p>
          <p
            class="d-flex align-self-start position-relative"
            v-if="item.module == 'Assistance'"
          >
            <span class="response mx-1">{{ item.reponse }}</span>
            <span class="position-absolute bottom-0 end-0">{{
              new Intl.DateTimeFormat("fr", {
                timeStyle: "short",
              }).format(new Date())
            }}</span>
          </p>
        </div>
      </div>
      <div v-else>
        <a-spin />
      </div>
      <div
        class="d-flex reponse-question"
        v-for="item in handleListMessage"
        :key="item"
      >
        <p
          class="d-flex align-self-end position-relative"
          v-if="item.sender_id == $store.state.user.id"
        >
          <span class="questions text-end mx-1" v-html="item.texte"></span>
          <span class="position-absolute bottom-0 end-0">{{
            new Intl.DateTimeFormat("fr", {
              timeStyle: "short",
            }).format(new Date(item.created_at))
          }}</span>
        </p>
        <p
          class="d-flex align-self-start position-relative"
          v-if="item.sender_id != $store.state.user.id"
        >
          <span class="response mx-1">{{ item.texte }}</span>
          <span class="position-absolute bottom-0 white">{{
            new Intl.DateTimeFormat("fr", {
              timeStyle: "short",
            }).format(new Date(item.created_at))
          }}</span>
        </p>
      </div>
    </div>
    <div class="footer">
      <p v-if="erroMsg" class="text-danger">Veuillez-remplir le champ</p>
      <form>
        <div class="d-flex align-content-center gap-3">
          <!-- <editor v-model="textChatBot" /> -->
          <textarea
            v-model="textChatBot"
            @keyup.enter.exact="sendWithChatBot"
            name=""
            @change="handleTexte"
            :placeholder="
              $store.state.isLanguage === 'FR'
                ? 'Saisissez votre message ici'
                : 'Enter your message here'
            "
            id="texte_area"
            cols="20"
          ></textarea>
          <button
            class="btn-lg button"
            type="submit"
            @click.prevent="sendWithChatBot"
            v-if="!isLoadingDiscussion"
          >
            <i class="bi bi-send-fill"></i>
          </button>
        </div>
      </form>
      <!-- <form>
        <div class="d-flex justify-content-between align-items-center gap-1">
          <textarea
            v-model="textChatBot"
            @keyup.enter.exact="sendWithChatBot"
            name=""
            :placeholder="
              $store.state.isLanguage === 'FR'
                ? 'Saisissez votre message ici'
                : 'Enter your message here'
            "
            id=""
            cols="20"
            class="w-100"
          ></textarea>
          <button
            class="btn-lg"
            @click.prevent="sendWithChatBot"
            v-if="!isLoadingDiscussion"
          >
            <i class="bi bi-send-fill"></i>
          </button>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </form> -->
    </div>
  </div>
</template>
<style scoped>
textarea {
  width: 88% !important;
}
span p {
  margin: 0 !important;
  padding: 0;
}
.button {
  position: absolute;
  right: 0;
  top: 0.5em;
}
.conteneur-message_chatBot {
  width: 100%;
  background: rgb(252, 252, 252);
  height: 51px;
}
.conteneur-message_chatBot > div {
  position: relative;
  width: 100%;
  height: 51px;
}
.white {
  right: 0;
}
.bottom-0 {
  font-size: 0.8em !important;
  font-weight: bold;
  bottom: -1.3em !important;
}
button {
  background: transparent;
  border: none;
}
.conteneur-img {
  width: 100%;
  height: 350px;
  border: 1px solid black;
  background: #128743;
  position: relative;
}
.titre {
  width: 55%;
}
.titre::after {
  content: "";
  background: #128743;
  display: inline-block;
  height: 10px;
  width: 20%;
  position: absolute;
  bottom: -0.5em;
  left: 0;
}
img {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.conteneur {
  width: 400px;
  height: 490px;
  position: relative;
  background: wheat;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.448);
  border-radius: 10px 10px 0 0;
}
.conteneur-discussion {
  background: rgb(249, 249, 249);
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}
.btn-conversation {
  background: #128743;
  color: white;
  border: none;
  padding: 0.5em;
}
.questions {
  background: #128743;
  color: white;
  width: auto;
  padding: 1em;
  border-radius: 10px 10px 5px 10px;
}
.reponse-question {
  flex-direction: column;
  gap: 1em;
}
.two-flex {
  flex: 2;
  padding: 0 1em;
}
.conteneur-discussion {
  border-bottom: thin solid gray;
}
.bi-send-fill {
  font-size: 1.5em;
  color: green;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.conteneur-conversations {
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  z-index: 30;
}
.bi-arrow-left-circle,
.bi-person-circle,
.bi-arrow-right {
  font-size: 1.5em;
  cursor: pointer;
}
.bi-arrow-right {
  color: orange;
}
.conteneur {
  width: 400px;
  height: 490px;
  position: relative;
  background: wheat;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.448);
  border-radius: 10px 10px 0 0;
}
.header {
  background: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.248);
  border-radius: 10px 10px 0 0;
}
.main {
  overflow: auto;
  width: 100%;
  gap: 1em;
  padding: 0.8em 0.8em 2em 0.8em;
  height: 379px;
  background: wheat;
}
.main_large {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  gap: 1em;
  padding: 0.8em 0.8em 2em 0.8em;
  height: 600px;
  background: wheat;
}
.response {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: auto;
  padding: 1em;
  border-radius: 10px 5px 10px 10px;
}
h6 {
  padding: 0.5em;
  background: white;
  border-radius: 10px;
  border: 2px solid green;
  color: rgb(90, 112, 90);
  cursor: pointer;
}
</style>
